import { useCategoriesWithFilter } from '@/services';
import { useSetupStore } from '../store';

export const useCategoriesOptions = () => {
  const [domain, operatorId] = useSetupStore((s) => [
    s.getDomain(),
    s.getOperatorId(),
  ]);
  const { data: categories } = useCategoriesWithFilter({ operatorId, domain });

  const getCategoryOptions = () => {
    return categories.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  };

  return {
    getCategoryOptions,
  };
};
