import { z } from 'zod';
import { fileExtensionSchema, mergingTypeSchema } from '../../shared';
import { Schema } from '../../../types';
import { EntryType, ReservedNodeType } from '@morph-mapper/types';
import { Input } from '@morph-mapper/node-inputs';

export const interModalDefaultExportSchema: Schema = {
  updateTemplateDiscardFields: [
    '_id',
    'id',
    'timestampedid',
    'hiddenreference',
    'dateofentry',
    'commissioner',
    'commissionerid',
    'clientidentifierraw',
    'clientidentifier',
  ],
  config: {
    _pdfConfig: {
      title: 'Pdf Settings',
      description: '',
      options: {
        separatePages: {
          type: z.boolean(),
          title: 'Separate Pages',
          description: 'Check to process all pages together.',
          input: Input.Checkbox,
          default: false,
        },
      },
      mutate: (options: any) => {
        const { separatePages } = options;

        if (separatePages === false) return undefined;
        return !separatePages;
      },
    },
    _updateConfig: {
      title: 'Update Template Settings',
      advanced: true,
      description: '',
      childConfigurations: [
        '_containerMergingConfig',
        '_containerSortDefault',
        '_containerFieldsConfig',
        '_skipDiffsConfig',
      ],
      options: {
        mergingType: {
          type: z.boolean(),
          title: 'Update Template',
          description: 'Check if this is an update to an existing template.',
          input: Input.Checkbox,
          default: '',
        },
      },
      mutate: (options: any) => {
        const { mergingType } = options;

        if (mergingType) {
          return 'extend';
        }
        return 'createIfNotExist';
      },
    },
    _containerMergingConfig: {
      title: '',
      advanced: true,
      description: '',
      options: {
        containerMerging: {
          type: z.string(),
          title: 'Container Merging',
          description:
            'Select whether single or all containers are being updated.',
          input: Input.SingleSelect,
          selection: [
            { label: 'Single', value: 'single' },
            { label: 'All Update', value: 'allUpdate' },
          ],
          default: 'allUpdate',
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { containerMerging } = options;

        return containerMerging;
      },
    },
    _containerFieldsConfig: {
      title: '',
      advanced: true,
      description: '',
      options: {
        containerFields: {
          type: z.array(z.string()),
          title: 'Container Fields',
          description: 'Select zero or more container fields to be updated.',
          input: Input.MultiSelect,
          placeholder: 'Select container fields',
          selection: [
            { label: 'import', value: 'import' },
            { label: 'container', value: 'container' },
          ],
          default: [],
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { containerFields } = options;

        return containerFields;
      },
    },
    _skipDiffsConfig: {
      title: '',
      advanced: true,
      description: '',
      options: {
        skipDiffs: {
          type: z.array(z.string()),
          title: 'Skip Fields',
          description: 'Select fields/items to be skipped.',
          input: Input.MultiSelect,
          placeholder: 'Select fields to ignore',
          selection: [
            { label: 'field 1', value: 'field 1' },
            { label: 'field2', value: 'field2' },
          ],
          default: [],
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { skipDiffs } = options;

        if (skipDiffs) return { ignoreitems: skipDiffs };

        return undefined;
      },
    },
    _containerSortDefault: {
      title: '',
      advanced: true,
      description: '',
      hidden: true,
      options: {
        sort: {
          type: z.boolean(),
          title: 'Sort Confitional Default (Hidden)',
          description: '',
          input: Input.Checkbox,
          default: true,
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { sort } = options;

        if (sort) return { dateofentry: -1 };
        return undefined;
      },
    },
    _autosubmitConfig: {
      title: 'Submission Settings',
      description: '',
      options: {
        autosubmit: {
          type: z.boolean(),
          title: 'Autosubmit Template',
          description:
            'Check if the template is ready and can be autosubmitted by default.',
          input: Input.Checkbox,
          default: false,
        },
      },
      mutate: (options: any) => {
        const { autosubmit } = options;

        if (!autosubmit) {
          return;
        }

        return {
          url: 'http://localhost:8080/InSight/TriggerReader',
          template: {
            semantics: 'product.ordermanagement.OrderAutoSubmitREST',
            selector: '$selector',
            stage: 'autosubmit',
          },
          skipIfSent: true,
        };
      },
    },
  },
  definition: {
    values: {
      [ReservedNodeType.DeclareVariable]: {
        values: {
          email: {
            validation: z.string(),
            displayName: 'Email domain',
            type: EntryType.Simple,
          },
        },
      },
      type: {
        validation: fileExtensionSchema,
        type: EntryType.Simple,
      },
      separatePages: {
        validation: z.boolean(),
        type: EntryType.Internal,
        configKey: '_pdfConfig',
      },
      checking: {
        validation: z.literal(
          JSON.stringify({
            '$utils.regexp.test': [
              '$emailMessage.from.0.address',
              ['$email', 'gi'],
            ],
          })
        ),
        type: EntryType.Internal,
      },
      template: {
        values: {
          [ReservedNodeType.DeclareVariable]: {
            values: {
              type: {
                validation: z.literal('Export'),
                type: EntryType.Internal,
              },
              clientidentifierraw: {
                validation: z.string(),
                type: EntryType.Internal,
                prefill: ({ name }) => `${name}`,
              },
              debtorraw: {
                validation: z.literal(
                  JSON.stringify([['Debtor ', '$clientidentifierraw']])
                ),
                type: EntryType.Internal,
              },
              clientreference: {
                validation: z.string(),
                displayName: 'Client reference',
                type: EntryType.Graph,
              },
              invoicereference: {
                validation: z.string(),
                displayName: 'Invoice reference',
                type: EntryType.Graph,
              },
              bookingtyperaw: {
                validation: z.literal(
                  JSON.stringify([
                    ['Bookingtype ', '$type', ' ', '$clientidentifierraw'],
                  ])
                ),
                type: EntryType.Internal,
              },
              tarifftyperaw: {
                validation: z.literal(
                  JSON.stringify([
                    ['Tarifftype ', '$type', ' ', '$clientidentifierraw'],
                  ])
                ),
                type: EntryType.Internal,
              },
              carriermerchant: {
                validation: z.literal(
                  JSON.stringify([
                    ['CarrierMerchant ', '$type', ' ', '$clientidentifierraw'],
                  ])
                ),
                type: EntryType.Internal,
              },
              shippingcompanyraw: {
                validation: z.string(),
                displayName: 'Shipping Company',
                type: EntryType.Graph,
              },
              selector: {
                validation: z.literal(
                  JSON.stringify({
                    _id: {
                      $exists: false,
                    },
                  })
                ),
                type: EntryType.Internal,
              },
            },
          },
          autosubmit: {
            validation: z.any(),
            type: EntryType.Internal,
            configKey: '_autosubmitConfig',
          },
          mergingType: {
            validation: mergingTypeSchema,
            type: EntryType.Internal,
            configKey: '_updateConfig',
          },
          containerMerging: {
            validation: z.string(),
            type: EntryType.Internal,
            configKey: '_containerMergingConfig',
          },
          sort: {
            validation: z.literal(JSON.stringify({ dateofentry: -1 })),
            type: EntryType.Internal,
            configKey: '_containerSortDefault',
          },
          containerFields: {
            validation: z.array(z.string()),
            type: EntryType.Internal,
            configKey: '_containerFieldsConfig',
          },
          skipDiff: {
            validation: z.literal(
              JSON.stringify({ ignoreitems: z.array(z.string()) })
            ),
            type: EntryType.Internal,
            configKey: '_skipDiffsConfig',
          },
          selector: {
            validation: z.literal('$selector'),
            type: EntryType.Internal,
          },
          doc: {
            values: {
              nlinkstate: {
                validation: z.literal('operational'),
                type: EntryType.Internal,
              },
              operatorid: {
                validation: z.string(),
                type: EntryType.Internal,
                prefill: ({ operatorId }) => operatorId,
              },
              templatetype: {
                validation: z.literal('order'),
                type: EntryType.Internal,
              },
              source: {
                validation: z.literal('$clientidentifierraw'),
                type: EntryType.Internal,
              },
              _id: {
                validation: z.literal(
                  JSON.stringify([
                    ['order_', '$clientidentifierraw', '_', '$clientreference'],
                  ])
                ),
                type: EntryType.Internal,
              },
              id: {
                validation: z.literal(
                  JSON.stringify([
                    ['$clientidentifierraw', '_', '$clientreference'],
                  ])
                ),
                type: EntryType.Internal,
              },
              timestampedid: {
                validation: z.literal(
                  JSON.stringify([
                    [
                      '$clientidentifierraw',
                      '_',
                      '$clientreference',
                      '_',
                      '#currentTimestamp',
                    ],
                  ])
                ),
                type: EntryType.Internal,
              },
              hiddenreference: {
                validation: z.literal(
                  JSON.stringify([
                    ['$clientidentifierraw', '_', '$clientreference'],
                  ])
                ),
                type: EntryType.Internal,
              },
              status: {
                validation: z.literal('create'),
                type: EntryType.Internal,
              },
              workload: {
                validation: z.literal('digest'),
                type: EntryType.Internal,
              },
              transportdirection: {
                validation: z.literal('E'),
                type: EntryType.Internal,
              },
              ordersession: {
                validation: z.literal('orderexport'),
                type: EntryType.Internal,
              },
              dateofentry: {
                validation: z.literal('#$currentTimestamp'),
                type: EntryType.Internal,
              },
              bookdate: {
                validation: z.literal('#$currentTimestamp'),
                type: EntryType.Internal,
              },
              bookingtyperaw: {
                validation: z.literal('$bookingtyperaw'),
                type: EntryType.Internal,
              },
              bookingtype: {
                validation: z.literal(
                  JSON.stringify({
                    $mongoRequest: [
                      {
                        '$db.ordermanagement_conversion.findOne': {
                          key: 'bookingtype',
                          commissionerid: '$clientidentifierraw',
                          crefcode: '$bookingtyperaw',
                        },
                        $result: {
                          $getVariable: 'orefcode',
                        },
                      },
                      'morphjs',
                      ['ordermanagement_conversion'],
                    ],
                  })
                ),
                type: EntryType.Internal,
              },
              type: {
                validation: z.literal('$type'),
                type: EntryType.Internal,
              },
              tarifftyperaw: {
                validation: z.literal('$tarifftyperaw'),
                type: EntryType.Internal,
              },
              tarifftype: {
                validation: z.literal(
                  JSON.stringify({
                    $mongoRequest: [
                      {
                        '$db.ordermanagement_conversion.findOne': {
                          key: 'tarifftype',
                          commissionerid: '$clientidentifierraw',
                          crefcode: '$tarifftyperaw',
                        },
                        $result: {
                          $getVariable: 'orefcode',
                        },
                      },
                      'morphjs',
                      ['ordermanagement_conversion'],
                    ],
                  })
                ),
                type: EntryType.Internal,
              },
              fileId: {
                validation: z.literal('$FILEID'),
                type: EntryType.Internal,
              },
              genericorigin: {
                validation: z.literal('$genericOrigin'),
                type: EntryType.Internal,
              },
              genericTemplateName: {
                validation: z.literal(
                  JSON.stringify([['$clientidentifierraw', ' Insert']])
                ),
                type: EntryType.Internal,
              },
              commissioner: {
                validation: z.literal('$clientidentifierraw'),
                type: EntryType.Internal,
              },
              commissionerid: {
                validation: z.literal('$clientidentifierraw'),
                type: EntryType.Internal,
              },
              clientidentifierraw: {
                validation: z.literal('$clientidentifierraw'),
                type: EntryType.Internal,
              },
              clientidentifier: {
                validation: z.literal(
                  JSON.stringify({
                    $mongoRequest: [
                      {
                        '$db.ordermanagement_conversion.findOne': {
                          key: 'clientidentifier',
                          commissionerid: '$clientidentifierraw',
                          crefcode: '$clientidentifierraw',
                        },
                        $result: {
                          $getVariable: 'orefcode',
                        },
                      },
                      'morphjs',
                      ['ordermanagement_conversion'],
                    ],
                  })
                ),
                type: EntryType.Internal,
              },
              debtor: {
                validation: z.literal(
                  JSON.stringify({
                    $mongoRequest: [
                      {
                        '$db.ordermanagement_conversion.findOne': {
                          key: 'debtor',
                          commissionerid: '$clientidentifierraw',
                          crefcode: '$debtorraw',
                        },
                        $result: {
                          $getVariable: 'orefcode',
                        },
                      },
                      'morphjs',
                      ['ordermanagement_conversion'],
                    ],
                  })
                ),
                type: EntryType.Internal,
              },
              debtorraw: {
                validation: z.literal('$debtorraw'),
                type: EntryType.Internal,
              },
              shippingcompanyraw: {
                validation: z.literal('$shippingcompanyraw'),
                type: EntryType.Internal,
              },
              shippingcompany: {
                validation: z.literal(
                  JSON.stringify({
                    $mongoRequest: [
                      {
                        '$db.ordermanagement_conversion.findOne': {
                          key: 'shippingcompany',
                          commissionerid: '$clientidentifierraw',
                          crefcode: '$shippingcompanyraw',
                        },
                        $result: {
                          $getVariable: 'orefcode',
                        },
                      },
                      'morphjs',
                      ['ordermanagement_conversion'],
                    ],
                  })
                ),
                type: EntryType.Internal,
              },
              carriermerchant: {
                validation: z.literal('$carriermerchant'),
                type: EntryType.Internal,
              },
              bookingreference: {
                validation: z.literal('$invoicereference'),
                type: EntryType.Internal,
              },
              clientreference: {
                validation: z.literal('$clientreference'),
                type: EntryType.Internal,
              },
              invoicereference: {
                validation: z.literal('$invoicereference'),
                type: EntryType.Internal,
              },
              containercounter: {
                validation: z.literal(JSON.stringify({ $count: [] })),
                type: EntryType.Internal,
              },
              containers: {
                outputType: 'array',
                values: {
                  [ReservedNodeType.DeclareVariable]: {
                    values: {
                      container: {
                        validation: z.string(),
                        displayName: 'Container',
                        type: EntryType.Graph,
                      },
                      containertyperaw: {
                        validation: z.string(),
                        displayName: 'Container type',
                        type: EntryType.Graph,
                      },
                      weight: {
                        validation: z.number(),
                        displayName: 'Weight',
                        type: EntryType.Graph,
                      },
                      sealnr1: {
                        validation: z.string(),
                        displayName: 'Seal',
                        type: EntryType.Graph,
                      },
                      colli: {
                        validation: z.number(),
                        displayName: 'Colli',
                        type: EntryType.Graph,
                      },
                      full: {
                        validation: z.enum(['F', 'E']),
                        displayName: 'Full/Empty',
                        type: EntryType.Internal,
                      },
                      cargodescription: {
                        validation: z.string(),
                        displayName: 'Cargo description',
                        type: EntryType.Graph,
                      },
                      billoflading: {
                        validation: z.string(),
                        displayName: 'Bill of lading',
                        type: EntryType.Graph,
                      },
                      pickupterminalraw: {
                        validation: z.string(),
                        displayName: 'Pickup terminal',
                        type: EntryType.Graph,
                      },
                      pickupreference: {
                        validation: z.string(),
                        displayName: 'Pickup reference',
                        type: EntryType.Graph,
                      },
                      pickupdatereadable: {
                        validation: z.number(),
                        displayName: 'Pickup date',
                        type: EntryType.Graph,
                      },
                      pickupdateformat: {
                        validation: z.enum([
                          'YYYY-MM-DD',
                          'YYYY-MMM-DD',
                          'YY-MM-DD',
                          'DD-MMM-YYYY',
                          'DD-MM-YYYY',
                          'DD-MM-YY',
                          'DD-MM',
                          'DDD',
                        ]),
                        displayName: 'Pickup date format',
                        type: EntryType.Simple,
                      },
                      latestpickupdatereadable: {
                        validation: z.number(),
                        displayName: 'Latest pickup date',
                        type: EntryType.Graph,
                      },
                      latestpickupdateformat: {
                        validation: z.enum([
                          'YYYY-MM-DD',
                          'YYYY-MMM-DD',
                          'YY-MM-DD',
                          'DD-MMM-YYYY',
                          'DD-MM-YYYY',
                          'DD-MM-YY',
                          'DD-MM',
                          'DDD',
                        ]),
                        displayName: 'Pickup date format',
                        type: EntryType.Simple,
                      },
                      deliveryterminalraw: {
                        validation: z.string(),
                        displayName: 'Delivery terminal',
                        type: EntryType.Graph,
                      },
                      deliveryreference: {
                        validation: z.string(),
                        displayName: 'Delivery reference',
                        type: EntryType.Graph,
                      },
                      closingdatereadable: {
                        validation: z.number(),
                        displayName: 'Closing date',
                        type: EntryType.Graph,
                      },
                      closingdateformat: {
                        validation: z.enum([
                          'YYYY-MM-DD',
                          'YYYY-MMM-DD',
                          'YY-MM-DD',
                          'DD-MMM-YYYY',
                          'DD-MM-YYYY',
                          'DD-MM-YY',
                          'DD-MM',
                          'DDD',
                        ]),
                        displayName: 'Closing date format',
                        type: EntryType.Simple,
                      },
                      cargoopeningdatereadable: {
                        validation: z.number(),
                        displayName: 'Cargo opening date',
                        type: EntryType.Graph,
                      },
                      cargoopeningdateformat: {
                        validation: z.enum([
                          'YYYY-MM-DD',
                          'YYYY-MMM-DD',
                          'YY-MM-DD',
                          'DD-MMM-YYYY',
                          'DD-MM-YYYY',
                          'DD-MM-YY',
                          'DD-MM',
                          'DDD',
                        ]),
                        displayName: 'Cargo opening date format',
                        type: EntryType.Simple,
                      },
                      inlandterminalinboundraw: {
                        validation: z.literal(
                          JSON.stringify([
                            [
                              'inboundTerminal ',
                              '$type',
                              ' ',
                              '$clientidentifierraw',
                            ],
                          ])
                        ),
                        type: EntryType.Internal,
                      },
                      inlandturninreference: {
                        validation: z.literal(
                          JSON.stringify([['$pickupreference']])
                        ),
                        type: EntryType.Internal,
                      },
                      inlandterminaloutboundraw: {
                        validation: z.literal(
                          JSON.stringify([
                            [
                              'OutboundTerminal ',
                              '$type',
                              ' ',
                              '$clientidentifierraw',
                            ],
                          ])
                        ),
                        type: EntryType.Internal,
                      },
                      inlandturnoutreference: {
                        validation: z.literal(
                          JSON.stringify([['$deliveryreference']])
                        ),
                        type: EntryType.Internal,
                      },
                      seavesselraw: {
                        validation: z.string(),
                        displayName: 'Sea Vessel',
                        type: EntryType.Graph,
                      },
                    },
                  },
                  invoicereference: {
                    validation: z.literal('$invoicereference'),
                    type: EntryType.Internal,
                  },
                  container: {
                    validation: z.literal('$container'),
                    type: EntryType.Internal,
                  },
                  containertyperaw: {
                    validation: z.literal('$containertyperaw'),
                    type: EntryType.Internal,
                  },
                  containertype: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              key: 'containertype',
                              commissionerid: '$clientidentifierraw',
                              crefcode: '$containertyperaw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  weight: {
                    validation: z.literal('$weight'),
                    type: EntryType.Internal,
                  },
                  sealnr1: {
                    validation: z.literal('$sealnr1'),
                    type: EntryType.Internal,
                  },
                  colli: {
                    validation: z.literal('$colli'),
                    type: EntryType.Internal,
                  },
                  full: {
                    validation: z.literal('$full'),
                    type: EntryType.Internal,
                  },
                  cargodescription: {
                    validation: z.literal('$cargodescription'),
                    type: EntryType.Internal,
                  },
                  _containerid: {
                    validation: z.string(),
                    type: EntryType.Internal,
                  },
                  containercounter: {
                    validation: z.number(),
                    type: EntryType.Internal,
                  },
                  export: {
                    values: {
                      billoflading: {
                        validation: z.literal('$billoflading'),
                        type: EntryType.Internal,
                      },
                      pickupterminal: {
                        validation: z.literal(
                          JSON.stringify({
                            $mongoRequest: [
                              {
                                '$db.ordermanagement_conversion.findOne': {
                                  key: 'pickupterminal',
                                  commissionerid: '$clientidentifierraw',
                                  crefcode: '$pickupterminalraw',
                                },
                                $result: {
                                  $getVariable: 'orefcode',
                                },
                              },
                              'morphjs',
                              ['ordermanagement_conversion'],
                            ],
                          })
                        ),
                        type: EntryType.Internal,
                      },
                      pickupterminalraw: {
                        validation: z.literal('$pickupterminalraw'),
                        type: EntryType.Internal,
                      },
                      pickupreference: {
                        validation: z.literal('$pickupreference'),
                        type: EntryType.Internal,
                      },
                      pickupdatereadable: {
                        validation: z.literal('$pickupdatereadable'),
                        type: EntryType.Internal,
                      },
                      pickupdate: {
                        validation: z.literal(
                          JSON.stringify({
                            '$time.stamp': [
                              [['$pickupdatereadable']],
                              '$pickupdateformat',
                            ],
                          })
                        ),
                        type: EntryType.Internal,
                      },
                      latestpickupdatereadable: {
                        validation: z.literal('$latestpickupdatereadable'),
                        type: EntryType.Internal,
                      },
                      latestpickupdate: {
                        validation: z.literal(
                          JSON.stringify({
                            '$time.stamp': [
                              [['$latestpickupdatereadable']],
                              '$latestpickupdateformat',
                            ],
                          })
                        ),
                        type: EntryType.Internal,
                      },
                      deliveryterminalraw: {
                        validation: z.literal('$deliveryterminalraw'),
                        type: EntryType.Internal,
                      },
                      deliveryterminal: {
                        validation: z.literal(
                          JSON.stringify({
                            $mongoRequest: [
                              {
                                '$db.ordermanagement_conversion.findOne': {
                                  key: 'deliveryterminal',
                                  commissionerid: '$clientidentifierraw',
                                  crefcode: '$deliveryterminalraw',
                                },
                                $result: {
                                  $getVariable: 'orefcode',
                                },
                              },
                              'morphjs',
                              ['ordermanagement_conversion'],
                            ],
                          })
                        ),
                        type: EntryType.Internal,
                      },
                      deliveryreference: {
                        validation: z.literal('$deliveryreference'),
                        type: EntryType.Internal,
                      },
                      closingdatereadable: {
                        validation: z.literal('$closingdatereadable'),
                        type: EntryType.Internal,
                      },
                      closingdate: {
                        validation: z.literal(
                          JSON.stringify({
                            '$time.stamp': [
                              [['$closingdatereadable']],
                              '$closingdateformat',
                            ],
                          })
                        ),
                        type: EntryType.Internal,
                      },
                      cargoopeningdatereadable: {
                        validation: z.literal('$cargoopeningdatereadable'),
                        type: EntryType.Internal,
                      },
                      cargoopeningdate: {
                        validation: z.literal(
                          JSON.stringify({
                            '$time.stamp': [
                              [['$cargoopeningdatereadable']],
                              '$cargoopeningdateformat',
                            ],
                          })
                        ),
                        type: EntryType.Internal,
                      },
                      inlandterminalinboundraw: {
                        validation: z.literal('$inlandterminalinboundraw'),
                        type: EntryType.Internal,
                      },
                      inlandterminalinbound: {
                        validation: z.literal(
                          JSON.stringify({
                            $mongoRequest: [
                              {
                                '$db.ordermanagement_conversion.findOne': {
                                  key: 'inlandterminalinbound',
                                  commissionerid: '$clientidentifierraw',
                                  crefcode: '$inlandterminalinboundraw',
                                },
                                $result: {
                                  $getVariable: 'orefcode',
                                },
                              },
                              'morphjs',
                              ['ordermanagement_conversion'],
                            ],
                          })
                        ),
                        type: EntryType.Internal,
                      },
                      inlandturninreference: {
                        validation: z.literal('$inlandturninreference'),
                        type: EntryType.Internal,
                      },
                      inlandterminaloutboundraw: {
                        validation: z.literal('$inlandterminaloutboundraw'),
                        type: EntryType.Internal,
                      },
                      inlandterminaloutbound: {
                        validation: z.literal(
                          JSON.stringify({
                            $mongoRequest: [
                              {
                                '$db.ordermanagement_conversion.findOne': {
                                  key: 'inlandterminaoutbound',
                                  commissionerid: '$clientidentifierraw',
                                  crefcode: '$inlandterminaloutboundraw',
                                },
                                $result: {
                                  $getVariable: 'orefcode',
                                },
                              },
                              'morphjs',
                              ['ordermanagement_conversion'],
                            ],
                          })
                        ),
                        type: EntryType.Internal,
                      },
                      inlandturnoutreference: {
                        validation: z.literal('$inlandturnoutreference'),
                        type: EntryType.Internal,
                      },
                      seavesselraw: {
                        validation: z.literal('$seavesselraw'),
                        type: EntryType.Internal,
                      },
                      seavessel: {
                        validation: z.literal(
                          JSON.stringify({
                            $mongoRequest: [
                              {
                                '$db.resource.findOne': {
                                  alias: '$seavesselraw',
                                },
                                $result: {
                                  $getVariable: 'CODE',
                                },
                              },
                              'morphjs',
                              ['resource'],
                            ],
                          })
                        ),
                        type: EntryType.Internal,
                      },
                      addresses: {
                        outputType: 'array',
                        values: {
                          [ReservedNodeType.DeclareVariable]: {
                            values: {
                              addressraw: {
                                validation: z.string(),
                                displayName: 'Inland address',
                                type: EntryType.Graph,
                              },
                              datereadable: {
                                validation: z.number(),
                                displayName: 'Loading date',
                                type: EntryType.Graph,
                              },
                              dateformat: {
                                validation: z.enum([
                                  'YYYY-MM-DD',
                                  'YYYY-MMM-DD',
                                  'YY-MM-DD',
                                  'DD-MMM-YYYY',
                                  'DD-MM-YYYY',
                                  'DD-MM-YY',
                                  'DD-MM',
                                  'DDD',
                                ]),
                                displayName: 'Loading date date format',
                                type: EntryType.Simple,
                              },
                              reference: {
                                validation: z.string(),
                                displayName: 'Loading reference',
                                type: EntryType.Graph,
                              },
                            },
                          },
                          _addressid: {
                            validation: z.string(),
                            type: EntryType.Internal,
                          },
                          loadingdischarge: {
                            validation: z.literal('L'),
                            type: EntryType.Internal,
                          },
                          addressraw: {
                            validation: z.literal('$addressraw'),
                            type: EntryType.Internal,
                          },
                          address: {
                            validation: z.literal(
                              JSON.stringify({
                                $mongoRequest: [
                                  {
                                    '$db.ordermanagement_conversion.findOne': {
                                      key: 'address',
                                      commissionerid: '$clientidentifierraw',
                                      crefcode: '$addressraw',
                                    },
                                    $result: {
                                      $getVariable: 'orefcode',
                                    },
                                  },
                                  'morphjs',
                                  ['ordermanagement_conversion'],
                                ],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          datereadable: {
                            validation: z.literal('$datereadable'),
                            type: EntryType.Internal,
                          },
                          date: {
                            validation: z.literal(
                              JSON.stringify({
                                '$time.stamp': [
                                  [['$datereadable']],
                                  '$dateformat',
                                ],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          reference: {
                            validation: z.literal('$reference'),
                            type: EntryType.Internal,
                          },
                        },
                      },
                      addresscounter: {
                        validation: z.number(),
                        type: EntryType.Graph,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};
