import { Menu, Portal } from '@mantine/core';
import { Options } from '@morph-mapper/ui';
import { FiTrash2 } from 'react-icons/fi';
import { HiOutlineDuplicate } from 'react-icons/hi';
import { useDeleteTemplate } from '@/services';
import { useSetupStore } from '../../store';
import { TbSettings } from 'react-icons/tb';
import { t } from 'i18next';

interface TemplateItemMenuProps {
  id: string;
}

export const TemplateItemMenu = ({ id }: TemplateItemMenuProps) => {
  const [setModalOpen, setMode, setTemplateId] = useSetupStore((s) => [
    s.setIsConfigModalOpen,
    s.setConfigModalMode,
    s.setSelectedTemplateId,
  ]);

  const { mutate: deleteTemplate } = useDeleteTemplate();

  const handleEdit = () => {
    setTemplateId(id);
    setMode('edit');
    setModalOpen(true);
  };

  const handleDuplicate = () => {
    setTemplateId(id);
    setMode('duplicate');
    setModalOpen(true);
  };

  const handleDelete = () => {
    deleteTemplate(id);
  };

  return (
    <Menu
      trigger="hover"
      openDelay={200}
      closeDelay={400}
      position="right-start"
    >
      <Menu.Target>
        <Options variant="default" />
      </Menu.Target>
      <Portal>
        <Menu.Dropdown>
          <Menu.Item icon={<TbSettings />} onClick={handleEdit}>
            {t('menu.item.configure')}
          </Menu.Item>
          <Menu.Item icon={<HiOutlineDuplicate />} onClick={handleDuplicate}>
            {t('menu.item.duplicate')}
          </Menu.Item>
          <Menu.Item icon={<FiTrash2 />} color={'red'} onClick={handleDelete}>
            {t('menu.item.deleteTemplate')}
          </Menu.Item>
        </Menu.Dropdown>
      </Portal>
    </Menu>
  );
};
