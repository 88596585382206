import _ from 'lodash';
import { StateCreator } from 'zustand';
import { createLens } from '@dhmk/zustand-lens';
import { SchemaType } from '@morph-mapper/schemas';
import { type SchemaVariant } from '@morph-mapper/types';
import { CustomSchemes, PreConfiguration } from '@/types';

export type ConfigSlice = {
  id: string | undefined;
  name: string | undefined;
  file: File | undefined;
  textContent: string | undefined;
  templateVariant: SchemaVariant | undefined;
  userId: string | undefined;
  operatorId: number | undefined;
  domainAccess: string[] | undefined;
  domain: string | undefined;
  type: SchemaType | undefined;
  preconfiguration: PreConfiguration;
  definedCustomSchemes: CustomSchemes | undefined;
  isDeployOnly: boolean;
  isSaveDisabled: boolean;
  isDocModalOpen: boolean;
  isSavingTemplate: boolean;
  isNavTemplateModalOpen: boolean;
  isNavDomainModalOpen: boolean;
  discardFields: string[];

  getId: () => string;
  setId: (id: string) => void;

  getFile: () => File;
  getFileExtension: () => string;
  setFile: (file: File) => void;

  setUserId: (userId: string) => void;
  getUserId: () => string;
  setOperatorId: (operatorId: number) => void;
  getOperatorId: () => number;
  setDomainAccess: (domainAccess: string[]) => void;
  getDomainAccess: () => string[];
  setDomain: (domain: string) => void;
  getDomain: () => string;

  setType: (type: SchemaType) => void;
  getType: () => SchemaType;

  setVariant: (variant: SchemaVariant) => void;
  getVariant: () => SchemaVariant;

  setName: (name: string) => void;
  getName: () => string;
  setTextContent: (textContent: string) => void;
  getTextContent: () => string;
  setIsSavingTemplate: (isSavingTemplate: boolean) => void;
  getIsSavingTemplate: () => boolean;
  setIsDeployOnly: (isDeployOnly: boolean) => void;
  getIsDeployOnly: () => boolean;
  setIsSaveDisabled: (isSaveDisabled: boolean) => void;
  getIsSaveDisabled: () => boolean;
  setIsDocModalOpen: (isDocModalOpen: boolean) => void;
  getIsDocModalOpen: () => boolean;
  setDefinedCustomSchemes: (customSchemes: CustomSchemes) => void;
  getDefinedCustomSchemes: () => CustomSchemes;
  setIsNavTemplateModalOpen: (isNavTemplateModalOpen: boolean) => void;
  getIsNavTemplateModalOpen: () => boolean;
  setisNavDomainModalOpen: (isNavDomainModalOpen: boolean) => void;
  getisNavDomainModalOpen: () => boolean;
  setPreconfiguration: (preconfiguration: PreConfiguration) => void;
  getPreconfiguration: () => PreConfiguration;
  updatePreconfiguration: (
    key: string,
    value: { key: string; options: Record<string, string> }
  ) => void;
  setDiscardFields: (discardFields: string[]) => void;
  getDiscardFields: () => string[];
};

export const createConfigSlice: StateCreator<
  { config: ConfigSlice },
  [['zustand/immer', never]],
  [],
  ConfigSlice
> = (_set, _get) => {
  // Slices make use of a namespace, make current slice local namespace.
  const [set, get] = createLens(_set, _get, ['config']);

  return {
    id: undefined,
    name: undefined,
    file: undefined,
    textContent: undefined,
    templateVariant: undefined,
    userId: undefined,
    operatorId: undefined,
    domainAccess: undefined,
    domain: undefined,
    type: undefined,
    preconfiguration: {},
    isSavingTemplate: false,
    isDeployOnly: false,
    isSaveDisabled: false,
    isDocModalOpen: false,
    isNavTemplateModalOpen: false,
    isNavDomainModalOpen: false,
    definedCustomSchemes: undefined,
    discardFields: [],

    getId() {
      const id = get().id;
      if (!id) {
        throw new Error('No id set');
      }
      return id;
    },
    setId(id: string) {
      set((s) => {
        s.id = id;
      });
    },

    setUserId(userId) {
      set((s) => {
        s.userId = userId;
      });
    },
    getUserId() {
      const userId = get().userId;
      if (!userId) {
        throw new Error('No userId set');
      }
      return userId;
    },

    setOperatorId(operatorId) {
      set((s) => {
        s.operatorId = operatorId;
      });
    },
    getOperatorId() {
      const operatorId = get().operatorId;
      if (!operatorId) {
        throw new Error('No operatorId set');
      }
      return operatorId;
    },

    setDomainAccess(domainAccess) {
      set((s) => {
        s.domainAccess = domainAccess;
      });
    },
    getDomainAccess() {
      const domainAccess = get().domainAccess;
      if (!domainAccess) {
        throw new Error('No domainAccess set');
      }
      return domainAccess;
    },

    setDomain(domain) {
      set((s) => {
        s.domain = domain;
      });
    },
    getDomain() {
      const domain = get().domain;
      if (!domain) {
        throw new Error('No domain set');
      }
      return domain;
    },

    setType(type) {
      set((s) => {
        s.type = type;
      });
    },
    getType() {
      const type = get().type;
      if (!type) {
        throw new Error('No type set');
      }
      return type;
    },

    setVariant(variant) {
      set((s) => {
        s.templateVariant = variant;
      });
    },
    getVariant() {
      const variant = get().templateVariant;
      if (!variant) {
        throw new Error('No variant set');
      }
      return variant;
    },

    setName: (name) => {
      set((s) => {
        s.name = name;
      });
    },
    getName: () => {
      const name = get().name;
      if (!name) {
        throw new Error('No name set');
      }
      return name;
    },

    setTextContent: (textContent) =>
      set(() => ({
        textContent,
      })),
    getTextContent: () => {
      const textContent = get().textContent;
      if (!textContent) {
        throw new Error('No text content set');
      }
      return textContent;
    },

    setDefinedCustomSchemes: (customSchemes) => {
      set((s) => {
        s.definedCustomSchemes = customSchemes;
      });
    },
    getDefinedCustomSchemes: () => {
      const customSchemes = get().definedCustomSchemes;
      if (!customSchemes) {
        throw new Error('No custom schemes set');
      }
      return customSchemes;
    },

    setPreconfiguration: (preconfiguration) => {
      set((s) => {
        s.preconfiguration = preconfiguration;
      });
    },
    getPreconfiguration: () => get().preconfiguration,
    updatePreconfiguration: (key, value) => {
      set((s) => {
        s.preconfiguration[key] = value;
      });
    },
    setDiscardFields: (discardFields) => {
      set((s) => {
        s.discardFields = discardFields;
      });
    },
    getDiscardFields: () => get().discardFields,
    getFile: () => {
      const file = get().file;
      if (!file) {
        throw new Error('No file loaded');
      }
      return file;
    },
    getFileExtension: () => {
      const file = get().file;

      if (!file) {
        throw new Error('No file loaded');
      }
      const extenstion = file.name.split('.').pop();
      if (!extenstion) {
        throw new Error('No file extension');
      }

      return extenstion;
    },
    setFile: (file) => {
      set((s) => {
        s.file = file;
      });
    },

    setIsSavingTemplate: (isSavingTemplate) => {
      set((s) => {
        s.isSavingTemplate = isSavingTemplate;
      });
    },
    getIsSavingTemplate: () => get().isSavingTemplate,

    setIsDeployOnly: (isDeployOnly) => {
      set((s) => {
        s.isDeployOnly = isDeployOnly;
      });
    },
    getIsDeployOnly: () => get().isDeployOnly,

    setIsSaveDisabled: (isSaveDisabled) => {
      set((s) => {
        s.isSaveDisabled = isSaveDisabled;
      });
    },
    getIsSaveDisabled: () => get().isSaveDisabled,
    setIsDocModalOpen: (isDocModalOpen) => {
      set((s) => {
        s.isDocModalOpen = isDocModalOpen;
      });
    },
    getIsDocModalOpen: () => get().isDocModalOpen,
    setIsNavTemplateModalOpen: (isNavTemplateModalOpen) => {
      set((s) => {
        s.isNavTemplateModalOpen = isNavTemplateModalOpen;
      });
    },
    getIsNavTemplateModalOpen: () => get().isNavTemplateModalOpen,
    setisNavDomainModalOpen: (isNavDomainModalOpen) => {
      set((s) => {
        s.isNavDomainModalOpen = isNavDomainModalOpen;
      });
    },
    getisNavDomainModalOpen: () => get().isNavDomainModalOpen,
  };
};
