import styled from "@emotion/styled";
import { Accordion } from "@mantine/core";


export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginTop: theme.spacing.md,
  backgroundColor: theme.colors.gray[1], 
  borderRadius: theme.radius.md,
  padding: theme.spacing.sm,
  border: `1px solid ${theme.colors.gray[3]}`,
}));

export const StyledAccordionControl = styled(Accordion.Control)(({ theme }) => ({
  fontSize: theme.fontSizes.md, 
  color: theme.colors.dark[7],
}));

export const StyledAccordionPanel = styled(Accordion.Panel)(({ theme }) => ({
  backgroundColor: theme.colors.gray[0],
  padding: theme.spacing.md,
  borderRadius: theme.radius.sm,
}));
