import api from '../api';
import {
  CreateTemplateResourceDto,
  TemplateCategory,
  TemplateDeploy,
  TemplateResource,
  UpdateTemplateResourceDto,
} from '@morph-mapper/types';
import { CategoryFilter, TemplateFilter } from './query';

export const fetchCategoriesWithFilter = async (
  params: CategoryFilter
): Promise<TemplateCategory[]> => {
  const { data } = await api.get(`/categories`, { params });
  return data;
};

export const createCategory = async (
  category: Omit<TemplateCategory, 'id'>
) => {
  const { data } = await api.post('/categories', category);
  return data;
};

export const deleteCategory = async (id: string) => {
  const { data } = await api.delete(`/categories/${id}`);
  return data;
};

export const updateCategory = async (
  id: string,
  ref: Partial<TemplateCategory>
) => {
  const { data } = await api.put(`/categories/${id}`, ref);
  return data;
};

export const fetchTemplates = async (
  params: TemplateFilter
): Promise<Omit<TemplateResource[], 'save'>> => {
  const { data } = await api.get('/templates', { params });
  return data;
};

export const fetchTemplateById = async (
  id: string
): Promise<TemplateResource> => {
  const { data } = await api.get(`/templates/${id}`);
  return data;
};

export const createTemplate = async (
  template: CreateTemplateResourceDto
): Promise<string> => {
  const { data } = await api.post('/templates', template);
  return data;
};

export const deleteTemplate = async (id: string) => {
  const { data } = await api.delete(`/templates/${id}`);
  return data;
};

export const updateTemplate = async (
  id: string,
  template: UpdateTemplateResourceDto
) => {
  const { data } = await api.put(`/templates/${id}`, template);
  return data;
};

export const fetchTemplateDeploy = async (id: string): Promise<string> => {
  const { data } = await api.get(`/templates/${id}/deploy`);
  return data;
};

export const updateTemplateDeploy = async (id: string, content: any) => {
  const { data } = await api.put(`/templates/${id}/deploy`, { content });
  return data;
};

export const deployTemplateSave = async (
  id: string,
  template: Omit<TemplateDeploy, 'id'>
) => {
  const { data } = await api.post(`/templates/${id}/deploy`, template);
  return data;
};
