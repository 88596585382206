import { Select } from '@mantine/core';
import { useState } from 'react';
import { replaceEscapedCharacter } from '@morph-mapper/utils';

interface SingleSelectCreatableProps {
  selection: {
    label: string;
    value: any;
  }[];
  value: string;
  handleChange: (value: string) => void;
}

export const SingleSelectCreatable = ({
  value,
  handleChange,
  selection,
}: SingleSelectCreatableProps) => {
  const initialValues = value
    ? [...selection, { label: value, value }]
    : selection;
  const [availableValues, setAvailableValues] =
    useState<{ label: string; value: string }[]>(initialValues);

  return (
    <Select
      searchable
      creatable
      clearable
      value={value}
      onChange={handleChange}
      getCreateLabel={(query) => `+ Create ${query}`}
      onCreate={(query) => {
        // TODO: this escape assumes the user actually wants to escape the character
        // make this ecape optional
        const item = {
          value: replaceEscapedCharacter(query),
          label: query,
        };
        setAvailableValues((current) => [...current, item]);
        return item;
      }}
      data={availableValues}
    />
  );
};
