import { Accordion } from "@mantine/core";
import { StyledAccordion, StyledAccordionControl, StyledAccordionPanel } from "./styles";


interface ConfigAccordionLayoutProps {
  title: string;
  children: React.ReactNode;
}

export const ConfigAccordionLayout = ({
  title,
  children,
}: ConfigAccordionLayoutProps) => {
  return (
    <StyledAccordion>
      <Accordion.Item value={title} >
        <StyledAccordionControl>{title}</StyledAccordionControl>
        <StyledAccordionPanel>{children}</StyledAccordionPanel>
      </Accordion.Item>
    </StyledAccordion>
  );
};
