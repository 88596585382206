import { SchemaType } from '@morph-mapper/schemas';
import { type SchemaVariant } from '@morph-mapper/types';
import { StateCreator } from 'zustand';

export interface SetupSlice {
  name: string | undefined;
  variant: SchemaVariant | undefined;
  type: SchemaType | undefined;
  file: File | undefined;
  templateId: string | undefined;
  currentStep: number;
  domain: string | undefined;
  operatorId: number | undefined;
  userId: string | undefined;

  isRenameCategoryOpen: boolean;
  isCreateModalOpen: boolean;
  isConfigModalOpen: boolean;
  configModalMode: 'edit' | 'duplicate';
  categoryId: string | undefined;
  selectedTemplateId: string | undefined;

  getName: () => string;
  getDomain: () => string;
  getType: () => SchemaType;
  getVariant: () => SchemaVariant;
  getOperatorId: () => number;
  getIUserId: () => string;

  setName: (name: string) => void;
  setVariant: (variant: SchemaVariant) => void;
  setType: (type: SchemaType) => void;
  setFile: (file: File) => void;
  setTemplateId: (templateId: string | undefined) => void;
  setCurrentStep: (currentStep: number) => void;
  setDomain: (domain: string) => void;
  setOperatorId: (operatorId: number) => void;
  setUserId: (userId: string) => void;

  setIsCreateModalOpen: (isOpen: boolean) => void;
  setIsConfigModalOpen: (isOpen: boolean) => void;
  setConfigModalMode: (mode: 'edit' | 'duplicate') => void;
  setCategoryId: (name: string) => void;
  clearCategoryId: () => void;

  getSelectedTemplateId: () => string;
  setSelectedTemplateId: (id: string) => void;
  clearSelectedTemplateId: () => void;
}

export const createSetupSlice: StateCreator<
  SetupSlice,
  [['zustand/immer', never]],
  [],
  SetupSlice
> = (set, get) => ({
  name: undefined,
  variant: undefined,
  type: undefined,
  schemaId: undefined,
  file: undefined,
  templateId: undefined,
  currentStep: 0,
  domain: undefined,
  operatorId: undefined,
  userId: undefined,

  isRenameCategoryOpen: false,
  isCreateModalOpen: false,
  isConfigModalOpen: false,
  configModalMode: 'edit',
  categoryId: undefined,
  selectedTemplateId: undefined,

  getName: () => {
    const name = get().name;
    if (!name) throw new Error('Name not set');

    return name;
  },
  getDomain: () => {
    const domain = get().domain;
    if (!domain) throw new Error('Domain not set');

    return domain;
  },
  getOperatorId: () => {
    const operatorId = get().operatorId;
    if (!operatorId) throw new Error('OperatorId not set');

    return operatorId;
  },
  getIUserId: () => {
    const userId = get().userId;
    if (!userId) throw new Error('UserId not set');

    return userId;
  },
  getType: () => {
    const type = get().type;
    if (!type) throw new Error('Type not set');

    return type;
  },
  getVariant: () => {
    const variant = get().variant;
    if (!variant) throw new Error('Variant not set');

    return variant;
  },

  setName: (name) => {
    set((s) => {
      s.name = name;
    });
  },
  setVariant: (variant) => {
    set((s) => {
      s.variant = variant;
    });
  },
  setType: (type) => {
    set((s) => {
      s.type = type;
    });
  },
  setFile: (file) => {
    set((s) => {
      s.file = file;
    });
  },

  setTemplateId: (templateId) => {
    set((s) => {
      s.templateId = templateId;
    });
  },
  setCurrentStep: (newStep) => {
    set((state) => {
      state.currentStep = newStep;
    });
  },
  setDomain: (selectedDomain) => {
    set((s) => {
      s.domain = selectedDomain;
    });
  },
  setOperatorId: (operatorId) => {
    set((s) => {
      s.operatorId = operatorId;
    });
  },
  setUserId: (userId) => {
    set((s) => {
      s.userId = userId;
    });
  },

  setIsCreateModalOpen: (isOpen) => {
    set((s) => {
      s.isCreateModalOpen = isOpen;
    });
  },

  setIsConfigModalOpen: (isOpen) => {
    set((s) => {
      s.isConfigModalOpen = isOpen;
    });
  },
  setConfigModalMode: (mode) => {
    set((s) => {
      s.configModalMode = mode;
    });
  },

  setCategoryId: (id) => {
    set((s) => {
      s.categoryId = id;
    });
  },
  clearCategoryId: () => {
    set((s) => {
      s.categoryId = undefined;
    });
  },
  getSelectedTemplateId: () => {
    const id = get().selectedTemplateId;
    if (!id) throw new Error('Selected template id not set');

    return id;
  },
  setSelectedTemplateId: (id) => {
    set((s) => {
      s.selectedTemplateId = id;
    });
  },
  clearSelectedTemplateId: () => {
    set((s) => {
      s.selectedTemplateId = undefined;
    });
  },
});
