import _ from 'lodash';
import { TreeItem, TreeMap, isTreeItem, isTreeMap } from '@/types';
import { useStore } from '@/store';
import { useLogicTree } from '@/hooks';
import { getKeys, getValues } from '@morph-mapper/utils';
import { EntryType } from '@morph-mapper/types';
import { P, match } from 'ts-pattern';
import Queue from 'queue-fifo';
import { useStructureBlocks } from '@morph-mapper/node-logic';
import { useDependencies } from './use-dependencies';
import { useEntryOverride } from './use-entry-override';
import { ReservedNodeType } from '@morph-mapper/types';
import { useSchemaEditor } from './use-schema-editor';
import { useRef } from 'react';
import { useTemplate } from '@/services';

export const useRenderTemplate = () => {
  const [entries, templateId, variant, entry] = useStore(
    ({ entries: e, config: c }) => [
      e.entries,
      c.getId(),
      c.getVariant(),
      e.entry,
    ]
  );
  const targetEntry = useRef<string | undefined>(undefined);
  const targetNode = useRef<string | undefined>(undefined);
  const { logicTreeToTemplate } = useLogicTree();
  const { structureBlocks } = useStructureBlocks(variant);
  const { retrieveDependencies } = useDependencies();
  const { override } = useEntryOverride();
  const { shouldRenderField } = useSchemaEditor();
  const { data: tempTemplate } = useTemplate(templateId);

  /**
   * Parses an entry item into a template object.
   *
   * @param entry Current entry to parse into the template object
   * @returns parsed value of the entry
   */
  const renderItemEntry = (entryId: string) => {
    const entry = entries[entryId];

    switch (entry.type) {
      case EntryType.Boolean:
        return entry.value;

      case EntryType.Simple:
        return entry.value;

      case EntryType.Cell: {
        const { column } = entry;
        if (column === undefined) return undefined;
        return `#>${column}$get`;
      }

      case EntryType.Graph: {
        const { id, graphId } = entry;
        if (graphId === undefined) {
          return undefined;
        }

        if (targetEntry.current === id && targetNode !== undefined) {
          return logicTreeToTemplate(graphId, targetNode.current);
        }

        return logicTreeToTemplate(graphId);
      }

      case EntryType.Internal:
        if (typeof entry.value === 'string') {
          if (entry.value.charAt(0) === '{' || entry.value.charAt(0) === '[') {
            return JSON.parse(entry.value);
          }
          return entry.value;
        }

        return entry.value;

      default:
        throw new Error('Invalid entry type');
    }
  };

  const getRoot = () => {
    const root = getValues(entries).find((e) => e.parentId === undefined);
    if (!root) throw new Error('Root not found');

    return root.id;
  };

  const buildChildQueue = (childrenIds: string[], pointer: string[]) => {
    return childrenIds.map((id) => {
      return match(entry(id).getType())
        .with(EntryType.Map, () => ({
          pointer,
          entryId: id,
        }))
        .otherwise(() => ({
          pointer: [...pointer, entry(id).getKey()],
          entryId: id,
        }));
    });
  };

  const populateEdiVariables = (template: any) => {
    if (tempTemplate === undefined || tempTemplate.ediVariables === undefined) {
      return template;
    }

    const replacementMap: Record<string, string> = {};
    Object.values(tempTemplate.ediVariables).forEach((variable: any) => {
      replacementMap[variable.schemaVariable] = variable.value;
    });

    const recursiveReplace = (obj: any): any => {
      if (typeof obj === 'string') {
        return replacementMap[obj] || obj;
      } else if (Array.isArray(obj)) {
        return obj.map(recursiveReplace);
      } else if (obj !== null && typeof obj === 'object') {
        return Object.fromEntries(
          Object.entries(obj).map(([key, value]) => [
            key,
            recursiveReplace(value),
          ])
        );
      }
      return obj;
    };

    return recursiveReplace(template);
  };

  const updateTemplate = (
    entryId: string,
    template: any,
    pointer: string[],
    registerMap?: (entry: TreeMap, objPointer: string[]) => void,
    registerItem?: (entry: TreeItem) => void
  ) => {
    if (!shouldRenderField(entryId)) {
      return;
    }

    match(override(entries[entryId]))
      .with(P.when(isTreeMap), (entry) => {
        const { key, outputType } = entry;
        const [objPointer, obj] = structureBlocks[outputType].render(key);

        match(_.get(template, pointer, undefined)).with(
          undefined,
          () => {
            _.set(template, pointer, obj);
          },
          (sibling) => {
            // TODO: do we want to check if we are overwriting parts of the template?
            // Currently might result in hard to debug issues.
            _.set(template, pointer, _.merge(sibling, obj));
          }
        );

        registerMap && registerMap(entry, objPointer);
      })
      .with(P.when(isTreeItem), (entry) => {
        // FIXME: this is a hotfix, but we should set the invariant sucht that the .value the actual template code
        // and not a string representation of the template code.
        if (typeof entry.value === 'string') {
          if (entry.value.charAt(0) === '{' || entry.value.charAt(0) === '[') {
            _.set(template, pointer, JSON.parse(entry.value));
          } else {
            _.set(template, pointer, entry.value);
          }
        } else {
          _.set(template, pointer, entry.value);
        }

        registerItem && registerItem(entry);
      })
      .exhaustive();
  };

  /**
   * Builds a template object for a specific entry.
   *
   * @returns A template object which can be parsed by the template engine.
   */
  const renderEntryWithContext = (entryId: string, nodeId?: string) => {
    // Verify that the target is defined,
    // if it is not defined we stop trying to render a template for this entry.
    const targetValue = entry(entryId).getTemplateValue();
    if (targetValue === undefined) {
      return undefined;
    }

    targetEntry.current = entryId;
    targetNode.current = nodeId;

    const dependencyIds = retrieveDependencies(entryId);
    const dependencyPaths = [...dependencyIds, entryId].reduce(
      (map, id) => map.set(id, entry(id).getPath()),
      new Map<string, string[]>()
    );
    const template = {};
    const templatePointers = new Map<string, string[]>();

    const getPointer = (index: number, path: string[]) => {
      if (index === 0) {
        return [];
      }

      const pointer = templatePointers.get(path[index - 1]);
      if (pointer === undefined) {
        throw 'Cache is inconsistent';
      }

      return pointer;
    };

    dependencyPaths.forEach((dependencyPath, dependencyId) => {
      dependencyPath.forEach((entryId, index) => {
        if (templatePointers.has(entryId)) {
          return;
        }

        const pointer = match(entry(entryId).getType())
          .with(EntryType.Map, () => {
            return getPointer(index, dependencyPath);
          })
          .otherwise(() => {
            return [
              ...getPointer(index, dependencyPath),
              entry(entryId).getKey(),
            ];
          });

        updateTemplate(
          entryId,
          template,
          pointer,
          (_, objPointer) => {
            templatePointers.set(entryId, [...pointer, ...objPointer]);
          },
          () => {
            templatePointers.set(entryId, pointer);
          }
        );
      });
    });

    // Resolve 'hiding' logic.
    const targetPointer = templatePointers.get(entryId);
    if (targetPointer === undefined) {
      console.error(
        'No target pointer found, skipping rendering of entry',
        entryId
      );
      return undefined;
    }

    try {
      exposeVariableForOutput(entryId, template, targetPointer);
      return template;
    } catch (e) {
      console.error('Error while rendering entry', entryId, e);
      return undefined;
    }
  };

  /**
   * Builds a template object from the template tree.
   *
   * @returns A template object which can be parsed by the template engine.
   */
  const renderTemplate = (fromNodeId?: string) => {
    const template: Record<string, any> = {};
    const entryId = fromNodeId ?? getRoot();

    const queue = new Queue<{ pointer: string[]; entryId: string }>();
    queue.enqueue({ pointer: [], entryId });

    while (queue.isEmpty() === false) {
      const { pointer, entryId } = queue.dequeue()!;

      updateTemplate(entryId, template, pointer, ({ children }, objPointer) => {
        buildChildQueue(getKeys(children.forwardMap), [
          ...pointer,
          ...objPointer,
        ]).map((child) => queue.enqueue(child));
      });
    }

    return populateEdiVariables(template);
  };

  return { renderEntryWithContext, renderTemplate, renderItemEntry };
};

const replaceTargetKey = (
  entryId: string,
  template: any,
  targetPath: string[],
  key: string
) => {
  const newPath = match(key)
    .with(P.string.regex(/^\$/), () => {
      throw `Cannot determine render output target for ${key}.`;
    })
    .with(P.string.regex(/^#/), (key) => {
      if (key !== ReservedNodeType.Iterator) {
        throw `Cannot determine render output target for ${key}.`;
      }

      const newPath = [
        ...targetPath.slice(0, -1),
        `__reserved__key__${entryId}`,
      ];
      const obj = _.get(template, targetPath);
      _.set(template, [...newPath, '#iterator'], obj);
      _.unset(template, targetPath);

      return newPath;
    })
    .otherwise(() => {
      const newPath = [
        ...targetPath.slice(0, -1),
        `__reserved__key__${entryId}`,
      ];
      const obj = _.get(template, targetPath);
      _.set(template, newPath, obj);
      _.unset(template, targetPath);

      return newPath;
    });

  return newPath;
};

/**
 * Exposes the target entry on the same level as the parent.
 * - When the parent exhibits non-hiding behaviour we don't have to do
 *  anything to expose the entry and return the path with the parent removed from the path.
 *
 * - When the parent exhibits hiding behaviour we inject template code to based on the parents functionality,
 *  we return the path which points to the currently exposed variable as defined in the injected template code.
 *
 * @returns the path which points to the parent of the highest level the entry is currently exposed on.
 */
const exposeFromParent = (
  entryId: string,
  template: any,
  path: string[],
  segments: string[]
): [string[], string | undefined] => {
  const updateObject = (path: string[], exposer: any) => {
    const targetReference = match(path.length)
      .with(0, () => template)
      .otherwise(() => _.get(template, path));

    if (targetReference === undefined) {
      _.set(template, path, exposer);
    } else {
      _.merge(targetReference, exposer);
    }
  };

  return match(path[path.length - 1])
    .with(P.string.regex(/^\$/), (key) => {
      return match(key)
        .with('$data', () => {
          const newPath = [...path];

          // Iterate from the end to the start of the array
          for (let i = newPath.length - 1; i >= 0; i--) {
            if (newPath[i] === '$data') {
              // Replace '$data' with '$Array.forEach'
              newPath[i] = '$Array.forEach';
            } else {
              // Stop replacing if the current element is not '$data'
              break;
            }
          }
          const exposer = {
            [`__reserved__key__${entryId}`]: `$data.__reserved__key__${entryId}`,
          };

          updateObject(newPath, exposer);
          return [newPath, undefined];
        })
        .with('$declareVariable', () => {
          const newPath = path.slice(0, -1);

          let internalPath = segments.reverse();
          if (
            internalPath[internalPath.length - 1] ===
            `__reserved__key__${entryId}`
          ) {
            internalPath = internalPath.slice(0, -1);
          }

          internalPath.push(`__reserved__key__${entryId}`);

          const exposer = {
            [`__reserved__key__${entryId}`]: `$${internalPath.join('.')}`,
          };

          updateObject(newPath, exposer);
          return [newPath, undefined];
        })
        .otherwise(() => {
          // TODO: determine all functional nodes which do still generate an output.
          if (['$Array.forEach'].includes(key)) {
            return [path.slice(0, -1), undefined];
          }
          throw `Cannot determine render output target for ${key}.`;
        });
    })
    .with(P.string.regex(/^#/), (key) => {
      throw `Cannot determine output, found key ${key} in parent path.`;
    })
    .otherwise(() => {
      return [path.slice(0, -1), path[path.length - 1]];
    }) as [string[], string | undefined];
};

export const exposeVariableForOutput = (
  entryId: string,
  template: any,
  targetPath: string[]
) => {
  const targetKey = targetPath[targetPath.length - 1];
  let path = replaceTargetKey(entryId, template, targetPath, targetKey);
  let segments = [];

  while (path.length > 0) {
    const [newPath, segment] = exposeFromParent(
      entryId,
      template,
      path,
      segments
    );
    path = newPath;

    // If there is no segment we performed an expose operation,
    // this makes all previous segments irrelevant. We reset the segments,
    // otherwise we add the segment to the list of segments (which is a reversed path).
    if (segment === undefined) {
      segments = [];
    } else {
      segments.push(segment);
    }
  }

  return template;
};
