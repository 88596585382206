import { useStore } from '../context';
import { parseStringToPath, getOrderedIds, renderReference } from '../utils';
import { match } from 'ts-pattern';
import { PathMode } from '../types';
import { getKeys } from '@morph-mapper/utils';
import { SchemaVariant, SearchPathResult } from '@morph-mapper/types';

interface IFileInteraction {
  /**
   *
   * @returns reference pointing to selected data based on the file format.
   */
  getPath: () => string | undefined;
  /**
   * Set the available relative path based on the active entry.
   * This path can be used in paths starting from the end of the relative path.
   *
   * @param path - relative path in string format.
   */
  setRelativePaths: (paths: SearchPathResult[]) => void;
  /**
   * Clear the relative path. Used for dismounting components using the relative path functionality.
   */
  clearRelativePath: () => void;
  /**
   * Get the selected cell location.
   */
  getSelectedCellLocaion: () => string | undefined;
}

export const useFileInteraction = (): IFileInteraction => {
  const [
    pathMode,
    absolutePath,
    setRelPaths,
    clearRelativePath,
    selectedRelativePath,
    cellLocation,
    variant,
  ] = useStore(({ path: p, file: f }) => [
    p.getPathMode(),
    p.getAbsolutePath(),
    p.setRelativePaths,
    p.clearRelativePaths,
    p.relativePath,
    p.getCellLocation(),
    f.getVariant(),
  ]);

  const getPath = () => {
    return match(pathMode)
      .with(PathMode.Absolute, () => {
        return match(variant)
          .with(SchemaVariant.Xml, SchemaVariant.Json, () => {
            return (
              '$inputdocument.' +
              getOrderedIds(absolutePath)
                .map((id) => renderReference(absolutePath[id]))
                .join('.')
            );
          })
          .otherwise(() => {
            return getOrderedIds(absolutePath)
              .map((id) => renderReference(absolutePath[id]))
              .join('.');
          });
      })
      .with(PathMode.Relative, () => {
        if (selectedRelativePath === undefined) {
          return undefined;
        }
        const { reference, path } = selectedRelativePath;

        const pathIds = getOrderedIds(absolutePath);
        const diff = pathIds.length - getKeys(path).length;

        return (
          `${reference}.` +
          pathIds
            .slice(-diff)
            .map((id) => renderReference(absolutePath[id]))
            .join('.')
        );
      })
      .exhaustive();
  };

  const setRelativePaths = (paths: SearchPathResult[]) => {
    if (!variant) {
      throw new Error("Variant is null. Unable to parse path.");
    }
  
    const parsed = paths.map(({ reference, value }) => ({
      reference,
      path: parseStringToPath(value, variant),
    }));

    setRelPaths(parsed);
  };

  const getSelectedCellLocaion = () => {
    return cellLocation;
  }

  return {
    getPath,
    getSelectedCellLocaion,
    setRelativePaths,
    clearRelativePath,
  };
};
