import { Box, TextInput } from '@mantine/core';
import { useCellSelector } from './use-cell-selector';
import type { InputProps } from '../../types';
import { Clipboard, CellClipboard, Reset } from '@morph-mapper/ui';

export const CellSelector = ({ value, handleChange }: InputProps<string>) => {
  const { handleSetCellPath, handleSetTextPath, handleClearPath, handleInputChange } =
    useCellSelector(handleChange);

  const inputValue = value ?? '';

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CellClipboard size={'lg'} mr={'md'} onClick={handleSetCellPath} />
      <Clipboard size={'lg'} mr={'md'} onClick={handleSetTextPath} />
      {inputValue !== '' && (
        <Reset
          size={'lg'}
          mr={'md'}
          onClick={handleClearPath}
          variant="default"
        />
      )}
      <TextInput
        value={inputValue}
        onChange={handleInputChange}
        sx={{ flexGrow: 1 }}
      />
    </Box>
  );
};
