import { useEffect, useState } from 'react';
import { useSetupStore } from '../../store';
import { useStore } from '@/store';
import { useLoadState } from '@/hooks';
import { useTemplate, useTemplateDeploy, useUpdateTemplate } from '@/services';
import { useNavigate } from 'react-router-dom';

export const useManageTemplate = (
  id: string,
  enabled: boolean,
  isStatic: boolean
) => {
  /* Global State */
  const [setId, setIsDeployOnly, isDeployOnly, setTextContent] = useStore(
    ({ config: c }) => [
      c.setId,
      c.setIsDeployOnly,
      c.getIsDeployOnly(),
      c.setTextContent,
    ]
  );
  /* Context State */
  const [currentStep, setCurrentStep] = useSetupStore((s) => [
    s.currentStep,
    s.setCurrentStep,
  ]);
  /* Local State */
  const [active, setActive] = useState(false);
  /* Hooks */
  const { load } = useLoadState();
  const navigate = useNavigate();
  /* Queries */
  const { mutate: updateTemplate } = useUpdateTemplate();
  const { data: template } = useTemplate(id);
  const { data: deploy } = useTemplateDeploy(id);

  useEffect(() => {
    if (!deploy) return;
    if (!isDeployOnly) return;

    setId(id);
    setTextContent(deploy);
    navigate('/editor');
  }, [deploy, isDeployOnly]);

  useEffect(() => {
    if (!template) return;
    if (!active) return;

    setId(id);
    load(template.save);
    setCurrentStep(currentStep + 1);
  }, [template, active]);

  const handleEnable = () => {
    updateTemplate({
      id,
      template: {
        enabled: !enabled,
      },
    });
  };

  const handleSelect = () => {
    if (isStatic) {
      setIsDeployOnly(true);
    }
    setActive(true);
  };

  return {
    handleEnable,
    handleSelect,
  };
};
