import { z } from 'zod';

export enum SchemaVariant {
  Pdf = 'pdf',
  Table = 'xls/csv',
  Xml = 'xml',
  Text = 'text',
  Email = 'email',
  Json = 'json',
}

export enum SchemaSource {
  File = 'file',
  Email = 'email',
  Http = 'http',
}

export enum EdiType {
  POST = 'post',
  GET = 'get',
  TokenBased = 'token',
}

export type EdiInput = {
  id: string;
  label: string;
  type: string;
  schemaVariable: string;
  internal?: boolean;
};

export const ediOptionInputs: Record<EdiType, EdiInput[]> = {
  post: [
    { id: 'user', label: 'User', type: 'text', schemaVariable: '$ediUser' },
    {
      id: 'password',
      label: 'Password',
      type: 'password',
      schemaVariable: '$ediPassword',
    },
  ],
  get: [
    { id: 'user', label: 'User', type: 'text', schemaVariable: '$ediUser' },
    {
      id: 'password',
      label: 'Password',
      type: 'password',
      schemaVariable: '$ediPassword',
    },
    {
      id: 'oauth',
      label: 'OAUTH',
      type: 'text',
      schemaVariable: '$ediOAuth',
      internal: true,
    },
  ],
  token: [
    { id: 'user', label: 'User', type: 'text', schemaVariable: '$ediUser' },
    {
      id: 'password',
      label: 'Password',
      type: 'text',
      schemaVariable: '$ediPassword',
    },
    {
      id: 'endpoint',
      label: 'Endpoint',
      type: 'text',
      schemaVariable: '$ediEndpoint',
    },
  ],
};

export const ediVariableNames = new Set(['ediUser', 'ediPassword', 'ediOAuth']);

export enum EntryType {
  Boolean = 'boolean',
  Simple = 'simple',
  Graph = 'graph',
  Internal = 'internal',
  Cell = 'cell',
  Map = 'map',
}

export enum ReservedNodeType {
  Data = '$data',
  DeclareVariable = '$declareVariable',
  Iterator = '#iterator',
}

export const reservedNames = new Set([
  'Array.classify',
  'Array.forEach',
  'Array.map',
  'CONTAINERNR',
  'FILEID',
  'RegExp',
  'db',
  'data',
  'declareVariable',
  'emailData',
  'emailMessage',
  'emailMessage.from.0.address',
  'emailMessage.text',
  'emailMessage.subject',
  'emailData.fileName',
  'pdfString',
  'flag',
  'genericOrigin',
  'getVariable',
  'keyGen',
  'mongoRequest',
  'options',
  'pdfString',
  'result',
  'skip',
  'time',
  'utils',
  'exists',
  'xls',
  'inputdocument',
]);

// The structure stores: [key, mappedReturnObject, wrapVariable]
export const reservedWrapVariables = new Map<string, [object, string]>([
  ['$xlsColumn', [{ '$data.val': [] }, '$genericXls.findVal']],
]);

export type EntryItemType = Exclude<EntryType, EntryType.Map>;

export const isZodEnum = (schema: any): schema is z.ZodEnum<any> => {
  if (!schema) return false;
  return schema instanceof z.ZodEnum;
};
