import { BaseHeader, Grid } from '@morph-mapper/ui';
import { Box, Group, Menu, Text } from '@mantine/core';
import { FiSave } from 'react-icons/fi';
import { RiQuestionLine } from 'react-icons/ri';
import { useEditorHeader } from './use-editor-header';
import { MdFirstPage, MdFormatListBulleted } from 'react-icons/md';

interface EditorHeaderProps {
  rawText: string;
}

export const EditorHeader = ({ rawText }: EditorHeaderProps) => {
  const saveDisabled = !rawText.trim();

  const {
    handleSaveFile,
    handleDocumentation,
    handleNavigateToTemplateSelection,
    handleNavigateToDomainSelection,
  } = useEditorHeader(rawText);

  return (
    <BaseHeader>
      <Text>MorphMapper</Text>
      <Group>
        <Box sx={{ display: 'flex', alignItems: 'center', zIndex: 100 }}>
          <Menu shadow="md" width={150} position="bottom-end">
            <Menu.Target>
              <Grid />
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Templates</Menu.Label>
              <Menu.Item
                disabled={saveDisabled}
                icon={<FiSave />}
                onClick={handleSaveFile}
              >
                Save
              </Menu.Item>
              <Menu.Label>Documentation</Menu.Label>
              <Menu.Item
                icon={<RiQuestionLine />}
                onClick={handleDocumentation}
              >
                Help
              </Menu.Item>
              <Menu.Label>Navigate</Menu.Label>
              <Menu.Item
                icon={<MdFormatListBulleted />}
                onClick={handleNavigateToTemplateSelection}
              >
                Template Selection
              </Menu.Item>
              <Menu.Item
                icon={<MdFirstPage />}
                onClick={handleNavigateToDomainSelection}
              >
                Domain Selection
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Box>
      </Group>
    </BaseHeader>
  );
};
