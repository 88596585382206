import { z } from 'zod';
import { EdiType, SchemaSource } from '../schemas';

export const templateRefSchema = z.object({
  id: z.string(),
  operatorId: z.number(),
  name: z.string(),
  path: z.string().nullable(),
  deployPath: z.string().nullable(),
  // TODO: where place domain
  // TODO: should be enum
  templatetype: z.string(),
  domain: z.string(),
  categoryId: z.string(),
  priority: z.number(),
  inputsource: z.nativeEnum(SchemaSource),
  description: z.string().optional(),
  enabled: z.boolean(),
  deleted: z.boolean().optional(),
  isStatic: z.boolean(),
  ediType: z.nativeEnum(EdiType).optional(),
  ediVariables: z
    .record(
      z.string(),
      z.object({
        value: z.union([z.string(), z.number()]),
        schemaVariable: z.string(),
      })
    )
    .optional(),
});

export type TemplateRef = z.infer<typeof templateRefSchema>;

// TODO: reduce duplication?
// TODO: stronger typing
export const templateSaveSchema = z.object({
  id: z.string(),
  operatorId: z.number(),
  name: z.string(),
  domain: z.string(),
  type: z.string(),
  variant: z.string(),
  entries: z.record(z.string(), z.any()),
  graphs: z.record(z.string(), z.any()),
  config: z.object({
    preconfiguration: z.any(),
    updateDiscardIds: z.array(z.string()),
  }),
});

export type TemplateSave = z.infer<typeof templateSaveSchema>;

export const templateResourceSchema = templateRefSchema
  .merge(
    z.object({
      save: templateSaveSchema,
    })
  )
  .omit({ path: true });

export type TemplateResource = z.infer<typeof templateResourceSchema>;

export const createTemplateResourceDtoSchema = templateResourceSchema
  .extend({
    save: z.string(),
  })
  .omit({ id: true });

export type CreateTemplateResourceDto = z.infer<
  typeof createTemplateResourceDtoSchema
>;

// Make everything optional
export const updateTemplateResourceDtoSchema = templateResourceSchema
  .extend({
    save: z.string().optional(),
  })
  .omit({ id: true })
  .partial();

export type UpdateTemplateResourceDto = z.infer<
  typeof updateTemplateResourceDtoSchema
>;

export const templateDeploySchema = z.object({
  id: z.string(),
  operatorId: z.number(),
  name: z.string(),
  domain: z.string(),
  type: z.string(),
  variant: z.string(),
  file: z.string(),
});

export type TemplateDeploy = z.infer<typeof templateDeploySchema>;

export const templateCategorySchema = z.object({
  id: z.string(),
  operatorId: z.number(),
  domain: z.string(),
  name: z.string(),
});

export type TemplateCategory = z.infer<typeof templateCategorySchema>;

export const rawTemplateDeploySchema = z.object({
  deployPath: z.string(),
  content: z.string(),
});

export type RawTemplateDeploy = z.infer<typeof rawTemplateDeploySchema>;
