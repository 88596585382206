import { z } from 'zod';
import { mergingTypeSchema } from '../../shared';
import { Schema } from '../../../types';
import { Input } from '@morph-mapper/node-inputs';
import { EntryType, ReservedNodeType } from '@morph-mapper/types';

export const interModalTableExportSchema: Schema = {
  updateTemplateDiscardFields: [
    '_id',
    'id',
    'timestampedid',
    'hiddenreference',
    'dateofentry',
    'commissioner',
    'commissionerid',
    'clientidentifierraw',
    'clientidentifier',
  ],
  config: {
    _xlsIterator: {
      title: 'Table',
      description: 'Define the table which structures the document.',
      options: {
        from: {
          type: z.string(),
          title: 'From Marker',
          description:
            'The marker in the file to start composing the table from',
          input: Input.CellSelector,
          default: '',
          options: {
            required: true,
          },
        },
        to: {
          type: z.string(),
          title: 'To Marker',
          description:
            'The marker in the file where to end composing the table',
          input: Input.TextSelector,
          default: '',
        },
        fromLine: {
          type: z.number(),
          title: 'From Line',
          description:
            'The number of lines to skip from the marker before starting to compose the table',
          input: Input.Number,
          default: 0,
        },
        shiftRow: {
          type: z.number(),
          title: 'Shift Row',
          description:
            'The number of rows to skip from the start of the table before iterating over values',
          input: Input.Number,
          default: 0,
        },
        findByColumns: {
          type: z.boolean(),
          title: 'Find by Columns',
          description:
            'Enable different compose algorithm to compose the table',
          input: Input.Checkbox,
          default: false,
        },
        columns: {
          type: z.any(),
          title: 'Columns',
          description:
            'Column definitions used to index the cells of the table',
          input: Input.Text,
          default: undefined,
        },
        skip: {
          type: z.any(),
          title: 'Skip',
          description: 'Define which rows to skip from the table',
          input: Input.Text,
          default: undefined,
        },
      },
      mutate: (options: any) => {
        const { from, to, findByColumns, columns, skip, fromLine, shiftRow } =
          options;

        return {
          table: {
            from,
            to,
            findByColumns,
            columns,
            skip,
            fromLine,
            shiftRow,
          },
        };
      },
    },
    _xlsClassify: {
      title: 'Classify',
      description: 'Group the table entries by a (combination of) identifiers.',
      options: {
        identifiers: {
          type: z.array(z.string()),
          title: 'Identifiers',
          description: 'The identifiers to group the table entries by',
          input: Input.MultiSelectCreatable,
          default: [],
        },
      },
      mutate: (options: any) => {
        const { identifiers } = options;
        if (!identifiers) return true;

        return {
          '$utils.concat.selective': identifiers.map((identifier: string) => {
            return {
              $getVariable: identifier,
            };
          }),
        };
      },
    },
    _updateConfig: {
      title: 'Update Template Settings',
      description: '',
      childConfigurations: [
        '_containerMergingConfig',
        '_containerSortDefault',
        '_containerFieldsConfig',
        '_skipDiffsConfig',
      ],
      options: {
        mergingType: {
          type: z.boolean(),
          title: 'Update Template',
          description: 'Check if this is an update to an existing template.',
          input: Input.Checkbox,
          default: '',
        },
      },
      mutate: (options: any) => {
        const { mergingType } = options;

        if (mergingType) {
          return 'extend';
        }
        return 'createIfNotExist';
      },
    },
    _containerMergingConfig: {
      title: '',
      description: '',
      options: {
        containerMerging: {
          type: z.string(),
          title: 'Container Merging',
          description:
            'Select whether single or all containers are being updated.',
          input: Input.SingleSelect,
          selection: [
            { label: 'Single', value: 'single' },
            { label: 'All Update', value: 'allUpdate' },
          ],
          default: 'allUpdate',
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { containerMerging } = options;

        return containerMerging;
      },
    },
    _containerFieldsConfig: {
      title: '',
      description: '',
      options: {
        containerFields: {
          type: z.array(z.string()),
          title: 'Container Fields',
          description: 'Select zero or more container fields to be updated.',
          input: Input.MultiSelect,
          placeholder: 'Select container fields',
          selection: [
            { label: 'import', value: 'import' },
            { label: 'container', value: 'container' },
          ],
          default: [],
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { containerFields } = options;

        return containerFields;
      },
    },
    _skipDiffsConfig: {
      title: '',
      description: '',
      options: {
        skipDiffs: {
          type: z.array(z.string()),
          title: 'Skip Fields',
          description: 'Select fields/items to be skipped.',
          input: Input.MultiSelect,
          placeholder: 'Select fields to ignore',
          selection: [
            { label: 'field 1', value: 'field 1' },
            { label: 'field2', value: 'field2' },
          ],
          default: [],
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { skipDiffs } = options;

        if (skipDiffs) return { ignoreitems: skipDiffs };

        return undefined;
      },
    },
    _containerSortDefault: {
      title: '',
      description: '',
      hidden: true,
      options: {
        sort: {
          type: z.boolean(),
          title: 'Sort Confitional Default (Hidden)',
          description: '',
          input: Input.Checkbox,
          default: true,
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { sort } = options;

        if (sort) return { dateofentry: -1 };
        return undefined;
      },
    },
    _autosubmitConfig: {
      title: 'Submission Settings',
      description: '',
      options: {
        autosubmit: {
          type: z.boolean(),
          title: 'Autosubmit Template',
          description:
            'Check if the template is ready and can be autosubmitted by default.',
          input: Input.Checkbox,
          default: false,
        },
      },
      mutate: (options: any) => {
        const { autosubmit } = options;

        if (autosubmit === false) {
          return undefined;
        }

        return {
          url: 'http://localhost:8080/InSight/TriggerReader',
          template: {
            semantics: 'product.ordermanagement.OrderAutoSubmitREST',
            selector: '$selector',
            stage: 'autosubmit',
          },
          skipIfSent: true,
        };
      },
    },
  },
  definition: {
    values: {
      [ReservedNodeType.DeclareVariable]: {
        values: {
          email: {
            validation: z.string(),
            displayName: 'Email domain',
            type: EntryType.Graph,
          },
        },
      },
      type: {
        validation: z.literal('xls'),
        type: EntryType.Internal,
      },
      checking: {
        validation: z.literal(
          JSON.stringify({
            '$utils.regexp.test': [
              '$emailMessage.from.0.address',
              ['$email', 'gi'],
            ],
          })
        ),
        type: EntryType.Internal,
      },
      template: {
        values: {
          '$Array.forEach': {
            outputType: 'forEach',
            values: {
              [ReservedNodeType.DeclareVariable]: {
                values: {
                  operatorid: {
                    validation: z.number(),
                    type: EntryType.Internal,
                    prefill: ({ operatorId }) => operatorId,
                  },
                  clientid: {
                    validation: z.string(),
                    type: EntryType.Internal,
                    prefill: ({ name }) => `${name}`,
                  },
                  selector: {
                    validation: z.literal(
                      JSON.stringify({
                        _id: {
                          $exists: false,
                        },
                      })
                    ),
                    type: EntryType.Internal,
                  },
                },
              },
              autosubmit: {
                validation: z.any(),
                type: EntryType.Internal,
                configKey: '_autosubmitConfig',
              },
              mergingType: {
                validation: mergingTypeSchema,
                type: EntryType.Internal,
                configKey: '_updateConfig',
              },
              containerMerging: {
                validation: z.string(),
                type: EntryType.Internal,
                configKey: '_containerMergingConfig',
              },
              sort: {
                validation: z.literal(JSON.stringify({ dateofentry: -1 })),
                type: EntryType.Internal,
                configKey: '_containerSortDefault',
              },
              containerFields: {
                validation: z.array(z.string()),
                type: EntryType.Internal,
                configKey: '_containerFieldsConfig',
              },
              skipDiff: {
                validation: z.literal(
                  JSON.stringify({ ignoreitems: z.array(z.string()) })
                ),
                type: EntryType.Internal,
                configKey: '_skipDiffsConfig',
              },
              selector: {
                validation: z.literal('$selector'),
                type: EntryType.Internal,
              },
              doc: {
                values: {
                  [ReservedNodeType.DeclareVariable]: {
                    values: {
                      clientidentifier: {
                        validation: z.literal('$data.0.clientidentifier'),
                        type: EntryType.Internal,
                      },
                    },
                  },
                  nlinkstate: {
                    validation: z.literal('operational'),
                    type: EntryType.Internal,
                  },
                  operatorid: {
                    validation: z.literal('$operatorid'),
                    type: EntryType.Internal,
                  },
                  workload: {
                    validation: z.literal('digest'),
                    type: EntryType.Internal,
                  },
                  templatetype: {
                    validation: z.literal('order'),
                    type: EntryType.Internal,
                  },
                  source: {
                    validation: z.literal('$data.0.clientidentifierraw'),
                    type: EntryType.Internal,
                  },
                  _id: {
                    validation: z.literal(
                      JSON.stringify([
                        [
                          'order_',
                          '$data.0.clientidentifierraw',
                          '_',
                          '$data.0.clientreference',
                        ],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  id: {
                    validation: z.literal(
                      JSON.stringify([
                        [
                          '$data.0.clientidentifierraw',
                          '_',
                          '$data.0.clientreference',
                        ],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  timestampedid: {
                    validation: z.literal(
                      JSON.stringify([
                        [
                          '$data.0.clientidentifierraw',
                          '_',
                          '$data.0.clientreference',
                          '_',
                          '#currentTimestamp',
                        ],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  hiddenreference: {
                    validation: z.literal(
                      JSON.stringify([
                        [
                          '$data.0.clientidentifierraw',
                          '_',
                          '$data.0.clientreference',
                        ],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  status: {
                    validation: z.literal('create'),
                    type: EntryType.Internal,
                  },
                  fileId: {
                    validation: z.literal('$FILEID'),
                    type: EntryType.Internal,
                  },
                  genericorigin: {
                    validation: z.literal('$genericOrigin'),
                    type: EntryType.Internal,
                  },
                  genericTemplateName: {
                    validation: z.literal(
                      JSON.stringify([
                        ['$data.0.clientidentifierraw', ' ', 'Insert'],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  commissioner: {
                    validation: z.literal('$data.0.clientidentifierraw'),
                    type: EntryType.Internal,
                  },
                  commissionerid: {
                    validation: z.literal('$data.0.clientidentifierraw'),
                    type: EntryType.Internal,
                  },
                  dateofentry: {
                    validation: z.literal('#$currentTimestamp'),
                    type: EntryType.Internal,
                  },
                  bookingtype: {
                    validation: z.literal('$data.0.bookingtype'),
                    type: EntryType.Internal,
                  },
                  type: {
                    validation: z.literal('Export'),
                    type: EntryType.Internal,
                  },
                  tarifftype: {
                    validation: z.literal('$data.0.tarifftype'),
                    type: EntryType.Internal,
                  },
                  transportdirection: {
                    validation: z.literal('E'),
                    type: EntryType.Internal,
                  },
                  ordersession: {
                    validation: z.literal('orderexport'),
                    type: EntryType.Internal,
                  },
                  bookdate: {
                    validation: z.literal('#$currentTimestamp'),
                    type: EntryType.Internal,
                  },
                  containercounter: {
                    validation: z.literal(JSON.stringify({ $count: [] })),
                    type: EntryType.Internal,
                  },
                  clientidentifierraw: {
                    validation: z.literal('$data.0.clientidentifierraw'),
                    type: EntryType.Internal,
                  },
                  clientidentifier: {
                    validation: z.literal('$data.0.clientidentifier'),
                    type: EntryType.Internal,
                  },
                  debtor: {
                    validation: z.literal('$data.0.debtor'),
                    type: EntryType.Internal,
                  },
                  debtorraw: {
                    validation: z.literal('$data.0.debtorraw'),
                    type: EntryType.Internal,
                  },
                  shippingcompanyraw: {
                    validation: z.literal('$data.0.shippingcompanyraw'),
                    type: EntryType.Internal,
                  },
                  shippingcompany: {
                    validation: z.literal('$data.0.shippingcompany'),
                    type: EntryType.Internal,
                  },
                  carriermerchant: {
                    validation: z.literal('$data.0.carriermerchant'),
                    type: EntryType.Internal,
                  },
                  bookingreference: {
                    validation: z.literal('$data.0.bookingreference'),
                    type: EntryType.Internal,
                  },
                  clientreference: {
                    validation: z.literal('$data.0.clientreference'),
                    type: EntryType.Internal,
                  },
                  containers: {
                    values: {
                      '$Array.forEach': {
                        outputType: 'forEach',
                        values: {
                          invoicereference: {
                            validation: z.literal('$data.invoicereference'),
                            type: EntryType.Internal,
                          },
                          container: {
                            validation: z.literal('$data.container'),
                            type: EntryType.Internal,
                          },
                          containertype: {
                            validation: z.literal('$data.containertype'),
                            type: EntryType.Internal,
                          },
                          containertyperaw: {
                            validation: z.literal('$data.containertyperaw'),
                            type: EntryType.Internal,
                          },
                          weight: {
                            validation: z.literal('$data.weight'),
                            type: EntryType.Internal,
                          },
                          sealnr1: {
                            validation: z.literal('$data.sealnr1'),
                            type: EntryType.Internal,
                          },
                          colli: {
                            validation: z.literal('$data.colli'),
                            type: EntryType.Internal,
                          },
                          full: {
                            validation: z.literal('$data.full'),
                            type: EntryType.Internal,
                          },
                          cargodescription: {
                            validation: z.literal('$data.cargodescription'),
                            type: EntryType.Internal,
                          },
                          export: {
                            values: {
                              billoflading: {
                                validation: z.literal('$data.billoflading'),
                                type: EntryType.Internal,
                              },
                              pickupterminal: {
                                validation: z.literal('$data.pickupterminal'),
                                type: EntryType.Internal,
                              },
                              pickupterminalraw: {
                                validation: z.literal(
                                  '$data.pickupterminalraw'
                                ),
                                type: EntryType.Internal,
                              },
                              pickupreference: {
                                validation: z.literal('$data.pickupreference'),
                                type: EntryType.Internal,
                              },
                              pickupdate: {
                                validation: z.literal('$data.pickupdate'),
                                type: EntryType.Internal,
                              },
                              latestpickupdate: {
                                validation: z.literal('$data.latestpickupdate'),
                                type: EntryType.Internal,
                              },
                              deliveryterminal: {
                                validation: z.literal('$data.deliveryterminal'),
                                type: EntryType.Internal,
                              },
                              deliveryterminalraw: {
                                validation: z.literal(
                                  '$data.deliveryterminalraw'
                                ),
                                type: EntryType.Internal,
                              },
                              deliveryreference: {
                                validation: z.literal(
                                  '$data.deliveryreference'
                                ),
                                type: EntryType.Internal,
                              },
                              closingdate: {
                                validation: z.literal('$data.closingdate'),
                                type: EntryType.Internal,
                              },
                              cargoopeningdate: {
                                validation: z.literal('$data.cargoopeningdate'),
                                type: EntryType.Internal,
                              },
                              inlandterminalinbound: {
                                validation: z.literal(
                                  '$data.inlandterminalinbound'
                                ),
                                type: EntryType.Internal,
                              },
                              inlandterminalinboundraw: {
                                validation: z.literal(
                                  '$data.inlandterminalinboundraw'
                                ),
                                type: EntryType.Internal,
                              },
                              inlandturninreference: {
                                validation: z.literal(
                                  '$data.inlandturninreference'
                                ),
                                type: EntryType.Internal,
                              },
                              inlandterminaloutbound: {
                                validation: z.literal(
                                  '$data.inlandterminaloutbound'
                                ),
                                type: EntryType.Internal,
                              },
                              inlandterminaloutboundraw: {
                                validation: z.literal(
                                  '$data.inlandterminaloutboundraw'
                                ),
                                type: EntryType.Internal,
                              },
                              inlandturnoutreference: {
                                validation: z.literal(
                                  '$data.inlandturnoutreference'
                                ),
                                type: EntryType.Internal,
                              },
                              seavesselraw: {
                                validation: z.literal('$data.seavesselraw'),
                                type: EntryType.Internal,
                              },
                              seavessel: {
                                validation: z.literal('$data.seavessel'),
                                type: EntryType.Internal,
                              },
                              addresses: {
                                outputType: 'array',
                                values: {
                                  loadingdischarge: {
                                    validation: z.literal('L'),
                                    type: EntryType.Internal,
                                  },
                                  address: {
                                    validation: z.literal('$data.address'),
                                    type: EntryType.Internal,
                                  },
                                  addressraw: {
                                    validation: z.literal('$data.addressraw'),
                                    type: EntryType.Internal,
                                  },
                                  date: {
                                    validation: z.literal('$data.date'),
                                    type: EntryType.Internal,
                                  },
                                  reference: {
                                    validation: z.literal('$data.reference'),
                                    type: EntryType.Internal,
                                  },
                                  _addressid: {
                                    validation: z.string(),
                                    type: EntryType.Internal,
                                  },
                                },
                              },
                              _containerid: {
                                validation: z.literal(
                                  JSON.stringify({
                                    '$math.plus': [
                                      {
                                        '$math.parseInt': '$CONTAINERNR',
                                      },
                                      1,
                                    ],
                                  })
                                ),
                                type: EntryType.Internal,
                              },
                              containercounter: {
                                validation: z.literal(
                                  JSON.stringify({
                                    '$math.plus': [
                                      {
                                        '$math.parseInt': '$CONTAINERNR',
                                      },
                                      1,
                                    ],
                                  })
                                ),
                                type: EntryType.Internal,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          $data: {
            outputType: 'data',
            values: {
              '$Array.classify': {
                validation: z.any(),
                type: EntryType.Internal,
                configKey: '_xlsClassify',
              },
              $data: {
                outputType: 'data',
                values: {
                  [ReservedNodeType.Iterator]: {
                    validation: z.any(),
                    type: EntryType.Internal,
                    configKey: '_xlsIterator',
                  },
                  [ReservedNodeType.DeclareVariable]: {
                    values: {
                      operatorid: {
                        validation: z.number(),
                        type: EntryType.Internal,
                        prefill: ({ operatorId }) => operatorId,
                      },
                      clientid: {
                        validation: z.string(),
                        type: EntryType.Internal,
                        prefill: ({ name }) => `${name}`,
                      },
                      direction: {
                        validation: z.literal(JSON.stringify('Export')),
                        type: EntryType.Internal,
                      },
                      clientidentifier: {
                        validation: z.literal(
                          JSON.stringify({
                            $mongoRequest: [
                              {
                                '$db.ordermanagement_conversion.findOne': {
                                  key: 'clientidentifier',
                                  commissionerid: '$clientid',
                                  crefcode: '$clientid',
                                },
                                $result: {
                                  $getVariable: 'orefcode',
                                },
                              },
                              'morphjs',
                              ['ordermanagement_conversion'],
                            ],
                          })
                        ),
                        type: EntryType.Internal,
                      },
                      debtorraw: {
                        validation: z.literal(
                          JSON.stringify([['Debtor ', '$clientid']])
                        ),
                        type: EntryType.Internal,
                      },
                      clientreference: {
                        validation: z.string(),
                        displayName: 'Client reference',
                        type: EntryType.Cell,
                      },
                      invoicereference: {
                        validation: z.string(),
                        displayName: 'Invoice reference',
                        type: EntryType.Cell,
                      },
                      bookingtyperaw: {
                        validation: z.literal(
                          JSON.stringify([
                            ['Bookingtype ', '$direction', ' ', '$clientid'],
                          ])
                        ),
                        type: EntryType.Internal,
                      },
                      tarifftyperaw: {
                        validation: z.literal(
                          JSON.stringify([
                            ['Tarifftype ', '$direction', ' ', '$clientid'],
                          ])
                        ),
                        type: EntryType.Internal,
                      },
                      carriermerchant: {
                        validation: z.literal(
                          JSON.stringify([
                            [
                              'CarrierMerchant ',
                              '$direction',
                              ' ',
                              '$clientid',
                            ],
                          ])
                        ),
                        type: EntryType.Internal,
                      },
                      shippingcompanyraw: {
                        validation: z.string(),
                        displayName: 'Shipping Company',
                        type: EntryType.Cell,
                      },
                      container: {
                        validation: z.string(),
                        displayName: 'Container',
                        type: EntryType.Cell,
                      },
                      weight: {
                        validation: z.number(),
                        displayName: 'Weight',
                        type: EntryType.Cell,
                      },
                      sealnr1: {
                        validation: z.string(),
                        displayName: 'Seal',
                        type: EntryType.Cell,
                      },
                      colli: {
                        validation: z.number(),
                        displayName: 'Colli',
                        type: EntryType.Cell,
                      },
                      full: {
                        validation: z.enum(['F', 'E']),
                        displayName: 'Full/Empty',
                        type: EntryType.Internal,
                      },
                      cargodescription: {
                        validation: z.string(),
                        displayName: 'Cargo description',
                        type: EntryType.Cell,
                      },
                      containertyperaw: {
                        validation: z.string(),
                        displayName: 'Container type',
                        type: EntryType.Cell,
                      },
                      billoflading: {
                        validation: z.string(),
                        displayName: 'Bill of lading',
                        type: EntryType.Cell,
                      },
                      pickupterminalraw: {
                        validation: z.string(),
                        displayName: 'Pickup terminal',
                        type: EntryType.Cell,
                      },
                      pickupreference: {
                        validation: z.string(),
                        displayName: 'Pickup reference',
                        type: EntryType.Cell,
                      },
                      pickupdatereadable: {
                        validation: z.number(),
                        displayName: 'Pickup date',
                        type: EntryType.Cell,
                      },
                      pickupdateformat: {
                        validation: z.enum([
                          'YYYY-MM-DD',
                          'YYYY-MMM-DD',
                          'YY-MM-DD',
                          'DD-MMM-YYYY',
                          'DD-MM-YYYY',
                          'DD-MM-YY',
                          'DD-MM',
                          'DDD',
                        ]),
                        displayName: 'Pickup date format',
                        type: EntryType.Simple,
                      },
                      latestpickupdatereadable: {
                        validation: z.number(),
                        displayName: 'Latest pickup date',
                        type: EntryType.Cell,
                      },
                      latestpickupdateformat: {
                        validation: z.enum([
                          'YYYY-MM-DD',
                          'YYYY-MMM-DD',
                          'YY-MM-DD',
                          'DD-MMM-YYYY',
                          'DD-MM-YYYY',
                          'DD-MM-YY',
                          'DD-MM',
                          'DDD',
                        ]),
                        displayName: 'Pickup date format',
                        type: EntryType.Simple,
                      },
                      deliveryterminalraw: {
                        validation: z.string(),
                        displayName: 'Delivery terminal',
                        type: EntryType.Cell,
                      },
                      deliveryreference: {
                        validation: z.string(),
                        displayName: 'Delivery reference',
                        type: EntryType.Cell,
                      },
                      closingdatereadable: {
                        validation: z.number(),
                        displayName: 'Closing date',
                        type: EntryType.Cell,
                      },
                      closingdateformat: {
                        validation: z.enum([
                          'YYYY-MM-DD',
                          'YYYY-MMM-DD',
                          'YY-MM-DD',
                          'DD-MMM-YYYY',
                          'DD-MM-YYYY',
                          'DD-MM-YY',
                          'DD-MM',
                          'DDD',
                        ]),
                        displayName: 'Closing date format',
                        type: EntryType.Simple,
                      },
                      cargoopeningdatereadable: {
                        validation: z.number(),
                        displayName: 'Cargo opening date',
                        type: EntryType.Cell,
                      },
                      cargoopeningdateformat: {
                        validation: z.enum([
                          'YYYY-MM-DD',
                          'YYYY-MMM-DD',
                          'YY-MM-DD',
                          'DD-MMM-YYYY',
                          'DD-MM-YYYY',
                          'DD-MM-YY',
                          'DD-MM',
                          'DDD',
                        ]),
                        displayName: 'Cargo opening date format',
                        type: EntryType.Simple,
                      },
                      inlandterminalinboundraw: {
                        validation: z.literal(
                          JSON.stringify([
                            [
                              'inboundTerminal ',
                              '$direction',
                              ' ',
                              '$clientid',
                            ],
                          ])
                        ),
                        type: EntryType.Internal,
                      },
                      inlandturninreference: {
                        validation: z.literal(
                          JSON.stringify([['$pickupreference']])
                        ),
                        type: EntryType.Internal,
                      },
                      inlandterminaloutboundraw: {
                        validation: z.literal(
                          JSON.stringify([
                            [
                              'OutboundTerminal ',
                              '$direction',
                              ' ',
                              '$clientid',
                            ],
                          ])
                        ),
                        type: EntryType.Internal,
                      },
                      inlandturnoutreference: {
                        validation: z.literal(
                          JSON.stringify([['$deliveryreference']])
                        ),
                        type: EntryType.Internal,
                      },
                      seavesselraw: {
                        validation: z.string(),
                        displayName: 'Sea Vessel',
                        type: EntryType.Cell,
                      },
                      addressraw: {
                        validation: z.string(),
                        displayName: 'Inland address',
                        type: EntryType.Cell,
                      },
                      datereadable: {
                        validation: z.number(),
                        displayName: 'Loading date',
                        type: EntryType.Cell,
                      },
                      dateformat: {
                        validation: z.enum([
                          'YYYY-MM-DD',
                          'YYYY-MMM-DD',
                          'YY-MM-DD',
                          'DD-MMM-YYYY',
                          'DD-MM-YYYY',
                          'DD-MM-YY',
                          'DD-MM',
                          'DDD',
                        ]),
                        displayName: 'Loading date date format',
                        type: EntryType.Simple,
                      },
                      reference: {
                        validation: z.string(),
                        displayName: 'Loading reference',
                        type: EntryType.Cell,
                      },
                    },
                  },
                  clientidentifierraw: {
                    validation: z.literal('$clientid'),
                    type: EntryType.Internal,
                  },
                  clientidentifier: {
                    validation: z.literal('$clientidentifier'),
                    type: EntryType.Internal,
                  },
                  debtorraw: {
                    validation: z.literal('$debtorraw'),
                    type: EntryType.Internal,
                  },
                  debtor: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              key: 'clientidentifier',
                              commissionerid: '$clientid',
                              crefcode: '$debtorraw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  clientreference: {
                    validation: z.literal('$clientreference'),
                    type: EntryType.Internal,
                  },
                  invoicereference: {
                    validation: z.literal('$invoicereference'),
                    type: EntryType.Internal,
                  },
                  bookingtyperaw: {
                    validation: z.literal('$bookingtyperaw'),
                    type: EntryType.Internal,
                  },
                  bookingtype: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              key: 'bookingtype',
                              commissionerid: '$clientid',
                              crefcode: '$bookingtyperaw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  tarifftyperaw: {
                    validation: z.literal('$tarifftyperaw'),
                    type: EntryType.Internal,
                  },
                  tarifftype: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              key: 'tarifftype',
                              commissionerid: '$clientid',
                              crefcode: '$tarifftyperaw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  carriermerchant: {
                    validation: z.literal('$carriermerchant'),
                    type: EntryType.Internal,
                  },
                  bookingreference: {
                    validation: z.literal('$invoicereference'),
                    type: EntryType.Internal,
                  },
                  shippingcompanyraw: {
                    validation: z.literal('$shippingcompanyraw'),
                    type: EntryType.Internal,
                  },
                  shippingcompany: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              key: 'shippingcompany',
                              commissionerid: '$clientid',
                              crefcode: '$shippingcompanyraw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  container: {
                    validation: z.literal('$container'),
                    type: EntryType.Internal,
                  },
                  weight: {
                    validation: z.literal('$weight'),
                    type: EntryType.Internal,
                  },
                  sealnr1: {
                    validation: z.literal('$sealnr1'),
                    type: EntryType.Internal,
                  },
                  colli: {
                    validation: z.literal('$colli'),
                    type: EntryType.Internal,
                  },
                  full: {
                    validation: z.literal('$full'),
                    type: EntryType.Internal,
                  },
                  cargodescription: {
                    validation: z.literal('$cargodescription'),
                    type: EntryType.Internal,
                  },
                  containertyperaw: {
                    validation: z.literal('$containertyperaw'),
                    type: EntryType.Internal,
                  },
                  containertype: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              key: 'containertype',
                              commissionerid: '$clientid',
                              crefcode: '$containertyperaw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  billoflading: {
                    validation: z.literal('$billoflading'),
                    type: EntryType.Internal,
                  },
                  pickupterminalraw: {
                    validation: z.literal('$pickupterminalraw'),
                    type: EntryType.Internal,
                  },
                  pickupterminal: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              key: 'pickupterminal',
                              commissionerid: '$clientid',
                              crefcode: '$pickupterminalraw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  pickupreference: {
                    validation: z.literal('$pickupreference'),
                    type: EntryType.Internal,
                  },
                  pickupdatereadable: {
                    validation: z.literal('$pickupdatereadable'),
                    type: EntryType.Internal,
                  },
                  pickupdate: {
                    validation: z.literal(
                      JSON.stringify({
                        '$time.stamp': [
                          [['$pickupdatereadable']],
                          '$pickupdateformat',
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  latestpickupdatereadable: {
                    validation: z.literal('$latestpickupdatereadable'),
                    type: EntryType.Internal,
                  },
                  latestpickupdate: {
                    validation: z.literal(
                      JSON.stringify({
                        '$time.stamp': [
                          [['$latestpickupdatereadable']],
                          '$latestpickupdateformat',
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  deliveryterminalraw: {
                    validation: z.literal('$deliveryterminalraw'),
                    type: EntryType.Internal,
                  },
                  deliveryterminal: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              key: 'deliveryterminal',
                              commissionerid: '$clientid',
                              crefcode: '$deliveryterminalraw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  deliveryreference: {
                    validation: z.literal('$deliveryreference'),
                    type: EntryType.Internal,
                  },
                  closingdatereadable: {
                    validation: z.literal('$closingdatereadable'),
                    type: EntryType.Internal,
                  },
                  closingdate: {
                    validation: z.literal(
                      JSON.stringify({
                        '$time.stamp': [
                          [['$closingdatereadable']],
                          '$closingdateformat',
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  cargoopeningdatereadable: {
                    validation: z.literal('$cargoopeningdatereadable'),
                    type: EntryType.Internal,
                  },
                  cargoopeningdate: {
                    validation: z.literal(
                      JSON.stringify({
                        '$time.stamp': [
                          [['$cargoopeningdatereadable']],
                          '$cargoopeningdateformat',
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  inlandterminalinboundraw: {
                    validation: z.literal('$inlandterminalinboundraw'),
                    type: EntryType.Internal,
                  },
                  inlandterminalinbound: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              key: 'inlandterminalinbound',
                              commissionerid: '$clientid',
                              crefcode: '$inlandterminalinboundraw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  inlandturninreference: {
                    validation: z.literal('$inlandturninreference'),
                    type: EntryType.Internal,
                  },
                  inlandterminaloutboundraw: {
                    validation: z.literal('$inlandterminaloutboundraw'),
                    type: EntryType.Internal,
                  },
                  inlandterminaloutbound: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              key: 'inlandterminaoutbound',
                              commissionerid: '$clientid',
                              crefcode: '$inlandterminaloutboundraw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  inlandturnoutreference: {
                    validation: z.literal('$inlandturnoutreference'),
                    type: EntryType.Internal,
                  },
                  seavesselraw: {
                    validation: z.literal('$seavesselraw'),
                    type: EntryType.Internal,
                  },
                  seavessel: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.resource.findOne': {
                              operatorid: '$operatorid',
                              alias: '$seavesselraw',
                            },
                            $result: {
                              $getVariable: 'CODE',
                            },
                          },
                          'morphjs',
                          ['resource'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  addressraw: {
                    validation: z.literal('$addressraw'),
                    type: EntryType.Internal,
                  },
                  address: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              key: 'address',
                              commissionerid: '$clientid',
                              crefcode: '$addressraw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  datereadable: {
                    validation: z.literal('$datereadable'),
                    type: EntryType.Internal,
                  },
                  date: {
                    validation: z.literal(
                      JSON.stringify({
                        '$time.stamp': [[['$datereadable']], '$dateformat'],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  reference: {
                    validation: z.literal('$reference'),
                    type: EntryType.Internal,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};
