import { useSetupStore } from '../../store';

export const useDomainItem = (domain: string) => {
  /* Context State */
  const [selectedDomain, setDomain] = useSetupStore((s) => [
    s.domain,
    s.setDomain,
  ]);

  const isSelected = selectedDomain === domain;

  /**
   * Handlers
   */
  const handleSelect = () => {
    setDomain(domain);
  };

  return { isSelected, handleSelect };
};
