import { SchemaConfigRecord } from '../types';
import { types as intermodalTypes } from './intermodal';
import { types as retailTypes } from './retail';
import { types as easyEOMTypes } from './easyeom';

import { customTypes as customRetailTypes } from './retail';

// TOEXTEND: add more schemas (domains)
export const SUPPORTED_SCHEMAS: SchemaConfigRecord = {
  intermodal: {
    name: 'Intermodal',
    displayName: 'Intermodal',
    description: 'Intermodal',
    schemas: intermodalTypes,
  },
  retail: {
    name: 'Retail',
    displayName: 'Retail',
    description: 'Retail',
    schemas: retailTypes,
    customSchemas: customRetailTypes,
  },
  easyeom: {
    name: 'EasyEOM',
    displayName: 'EasyEOM',
    description: 'EasyEOM',
    schemas: easyEOMTypes,
  },
};
