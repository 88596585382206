import { useFileInteraction } from '@morph-mapper/file-viewer';

export const useCellSelector = (handleChange: (value: string) => void) => {
  const { getPath, getSelectedCellLocaion } = useFileInteraction();

  const handleSetTextPath = () => {
    const path = getPath();

    if (path !== undefined) {
      handleChange(path);
    }
  };

  const handleSetCellPath = () => {
    const cellLocation = getSelectedCellLocaion();

    if (cellLocation !== undefined) {
      handleChange(cellLocation);
    }
  };

  const handleClearPath = () => {
    handleChange('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    handleChange(newValue); 
  };

  return { handleSetTextPath, handleSetCellPath, handleClearPath, handleInputChange };
};
