import { SchemaType } from '@morph-mapper/schemas';
import type { SchemaVariant, TemplateSave } from '@morph-mapper/types';
import { getValues } from '@morph-mapper/utils';
import { useStore } from '@/store';
import { TreeNode } from '@/types';
import { parseZodSchema } from '@/utils';
import { z } from 'zod';

export const useLoadState = () => {
  /* Global state */
  const [
    setName,
    setDomain,
    setType,
    setVariant,
    setPreconfiguration,
    setDiscardFields,
    setEntries,
    setGraphs,
  ] = useStore(({ config: c, entries: e, graphs: g }) => [
    c.setName,
    c.setDomain,
    c.setType,
    c.setVariant,
    c.setPreconfiguration,
    c.setDiscardFields,
    e.setEntries,
    g.setGraphs,
  ]);

  /**
   * Handlers
   */
  const replaceNull = (obj: any) => {
    for (const k in obj) {
      if (typeof obj[k] == 'object' && obj[k] !== null) {
        replaceNull(obj[k]);
      } else if (obj[k] === null) {
        obj[k] = undefined;
      }
    }

    return obj;
  };

  // TODO: this is a pre-hydrated treenode, update typing
  const parseEntries = (entries: Record<string, TreeNode>) => {
    return replaceNull(
      getValues(entries).reduce((acc, entry: TreeNode) => {
        // TODO: why can this happen? Fix: ZodUnknown for new environment variables
        const validation = entry.validation
          ? parseZodSchema(entry.validation)
          : z.any();

        acc[entry.id] = { ...entry, validation };

        return acc;
      }, {} as Record<string, TreeNode>)
    );
  };

  const load = (template: Omit<TemplateSave, 'id'>) => {
    const { entries, graphs, name, domain, type, variant, config } = template;
    const { updateDiscardIds, preconfiguration } = config;

    setName(name);
    setDomain(domain);
    // TODO: typing
    setType(type as SchemaType);
    setVariant(variant as SchemaVariant);
    setEntries(parseEntries(entries));
    setGraphs(graphs);
    setPreconfiguration(preconfiguration);
    setDiscardFields(updateDiscardIds);
  };

  return { load };
};
