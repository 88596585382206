import { CustomSchemes } from '@/types';
import api from '../api';

export const fetchDomainsbyOperatorId = async (
  id: string
): Promise<string[]> => {
  const { data } = await api.get(`/operators/${id}/domains`);
  return data;
};

export const fetchSchemesbyOperatorId = async (
  id: string
): Promise<CustomSchemes> => {
  const { data } = await api.get(`/operators/${id}/schemes`);
  return data || {};
};

export const fetchLanguagebyOperatorId = async (
  id: string
): Promise<string | undefined> => {
  const { data } = await api.get(`/operators/${id}/language`);
  return data || undefined;
};
