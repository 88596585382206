import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useForm, UseFormReturnType } from '@mantine/form';
import { useSetupStore } from '../../store';
import { useLoadState, useNotify } from '@/hooks';
import { useStore } from '@/store';
import { useSchema } from '@morph-mapper/schemas';
import { stringifyState } from '@morph-mapper/utils';
import {
  useCategoriesWithFilter,
  useCreateTemplate as useCreateTemplateQuery,
} from '@/services';
import { FormValues, TransformFormValues, TransformValues } from './types';
import { SchemaSource } from '@morph-mapper/types';
import {
  EdiFormValues,
  SourceFormValues,
  useCategoriesOptions,
  useEdiOptions,
  useSourceOptions,
  useTemplateBuilder,
} from '../../hooks';

export const useCreateTemplate = () => {
  /* Global State */
  const [setId, definedCustomSchemes] = useStore(({ config: c }) => [
    c.setId,
    c.definedCustomSchemes,
  ]);
  /* Context State */
  const [operatorId, domain, isOpen, step, setOpen, setStep] = useSetupStore(
    (s) => [
      s.getOperatorId(),
      s.getDomain(),
      s.isCreateModalOpen,
      s.currentStep,
      s.setIsCreateModalOpen,
      s.setCurrentStep,
    ]
  );
  /* Local State */
  const [template, setTemplate] = useState<any>(undefined);
  /* Hooks */
  const { error } = useNotify();
  const { load } = useLoadState();
  const { getSchemaTypes, getSchemaVariants } = useSchema(
    domain,
    definedCustomSchemes
  );
  const { buildTemplate } = useTemplateBuilder(operatorId, domain);
  const form = useForm<FormValues, TransformValues>({
    validate: {
      name: (value) =>
        value.trim() === '' ? t('message.error.required') : null,
      categoryId: (value) =>
        value === undefined ? t('message.error.required') : null,
      type: (value) =>
        value === undefined ? t('message.error.required') : null,
      variant: (value) =>
        value === undefined ? t('message.error.required') : null,
      ediVariables: {
        user: (value) => (value === '' ? t('message.error.required') : null),
        password: (value) =>
          value === '' ? t('message.error.required') : null,
        endpoint: (value) =>
          value === '' ? t('message.error.required') : null,
      },
    },
    transformValues: (values) => {
      const { ediType, ediVariables } = values;

      return {
        ...values,
        ediVariables: transformEdiVariables(ediType, ediVariables),
      };
    },
  });
  const { getCategoryOptions } = useCategoriesOptions();
  // Due to typing limitations we cast the form to a subset of the form values.
  const { getSourceOptions, handleSourceChange } = useSourceOptions(
    form as unknown as UseFormReturnType<SourceFormValues & EdiFormValues>
  );
  const {
    getEdiTypes,
    getEdiInputs,
    handleEdiOptionChange,
    transformEdiVariables,
  } = useEdiOptions(form as unknown as UseFormReturnType<EdiFormValues>);
  /* Queries */
  const { data: categories, isFetched: categoriesFetched } =
    useCategoriesWithFilter({ operatorId, domain });
  const {
    mutate: createTemplate,
    isSuccess: createSuccess,
    data: templateId,
  } = useCreateTemplateQuery();

  /**
   * Handlers
   */
  const loadInitialValues = (categoryId: string) => {
    const type = getSchemaTypes()[0];
    const variant = getSchemaVariants(type)[0];

    form.setValues({
      name: '',
      description: '',
      priority: 0,
      categoryId,
      type,
      variant,
      inputsource: SchemaSource.File,
      ediType: undefined,
      ediVariables: {},
    });
  };

  const getSchemaTypeOptions = () => {
    return getSchemaTypes().map((type) => ({
      key: type,
      value: type,
      label: type.charAt(0).toUpperCase() + type.slice(1),
    }));
  };

  const getSchemaVariantOptions = () => {
    const type = form.values.type;

    return getSchemaVariants(type).map((variant) => ({
      key: variant,
      value: variant,
      label: variant.charAt(0).toUpperCase() + variant.slice(1),
    }));
  };

  const submit = (values: TransformFormValues) => {
    const { name, type, variant } = values;
    const save = buildTemplate(name, type, variant);

    if (save === undefined) {
      error(t('message.error.templateCreationFailed'));
      return;
    }

    createTemplate({
      ...values,
      operatorId,
      deployPath: null,
      templatetype: 'MorphMapper',
      domain,
      enabled: false,
      isStatic: false,
      save: stringifyState(save),
    });

    setTemplate(save);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  /**
   * Effects
   */
  // Wait for the categories to be loaded before setting the initial values
  useEffect(() => {
    if (categoriesFetched === false) return;

    const defaultCategory = categories.find(
      (category) => category.name === 'default'
    );
    // This should never happen, the default category is managed by the backend
    if (defaultCategory === undefined) {
      error(t('message.error.defaultCategoryMissing'));
      setOpen(false);
      return;
    }

    loadInitialValues(defaultCategory.id);
  }, [categoriesFetched, categories]);

  // On successful template creation we redirect the user to the newly created template
  useEffect(() => {
    if (createSuccess === false) return;

    setId(templateId);
    load(template);
    setStep(step + 1);
    setOpen(false);
  }, [createSuccess]);

  return {
    form,
    getSchemaTypeOptions,
    getSchemaVariantOptions,
    getSourceOptions,
    getCategoryOptions,
    getEdiTypes,
    getEdiInputs,
    handleEdiOptionChange,
    handleSourceChange,
    isOpen,
    handleCloseModal,
    submit,
  };
};
