import { UseFormReturnType } from '@mantine/form';
import { EdiType, SchemaSource } from '@morph-mapper/types';
import { EdiFormValues } from './use-edi-options';
import { getValues } from '@morph-mapper/utils';
import { match } from 'ts-pattern';

export type SourceFormValues = {
  inputsource: SchemaSource;
};

const getSourceOptions = () => {
  return getValues(SchemaSource).map((source) => ({
    key: source,
    value: source,
    label: source.charAt(0).toUpperCase() + source.slice(1),
  }));
};

export const useSourceOptions = (
  form: UseFormReturnType<SourceFormValues & EdiFormValues>
) => {
  // EDI variables are only used when the source is HTTP
  const handleSourceChange = (source: SchemaSource) => {
    form.setFieldValue('inputsource', source);

    match(source)
      .with(SchemaSource.Http, () => {
        form.setFieldValue('ediType', EdiType.POST);
      })
      .otherwise(() => {
        form.setFieldValue('ediType', undefined);
        form.setFieldValue('ediVariables', {});
      });
  };

  return { getSourceOptions, handleSourceChange };
};
