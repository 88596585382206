export enum Input {
  Text = 'text',
  Number = 'number',
  Checkbox = 'checkbox',
  TextInput = 'text-input',
  MultiSelectCreatable = 'multi-select-creatable',
  TextSelector = 'text-selector',
  RadioSelector = 'radio-selector',
  SingleSelect = 'single-select',
  SingleSelectCreatable = 'single-select-creatable',
  Literal = 'literal',
  ParameterTable = 'parameter-table',
  MultiSelect = 'multi-select',
  CellSelector = 'cell-selector',
}

export type InputProps<T> = {
  placeholder?: string;
  // TODO: should be optional?
  selection: {
    label: string;
    value: T extends (infer U)[] ? U : T;
  }[];
  value: T;
  handleChange: (value: T) => void;
};
