import { useUpdateTemplate } from '@/services';
import { useStore } from '@/store';
import { useEffect } from 'react';
import { useNotify } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { stringifyState } from '@morph-mapper/utils';

export const useSaveState = () => {
  /* Global state */
  const [
    operatorId,
    id,
    name,
    domain,
    type,
    variant,
    preconfiguration,
    discardFields,
    isSavingTemplate,
    setIsSavingTemplate,
    userId,
    entries,
    graphs,
  ] = useStore(({ config: c, entries: e, graphs: g }) => [
    c.getOperatorId(),
    c.getId(),
    c.getName(),
    c.getDomain(),
    c.getType(),
    c.getVariant(),
    c.getPreconfiguration(),
    c.getDiscardFields(),
    c.isSavingTemplate,
    c.setIsSavingTemplate,
    c.userId,
    e.entries,
    g.graphs,
  ]);
  /* Queries */
  const { mutate: updateTemplate, isSuccess, isError } = useUpdateTemplate();

  const { success } = useNotify();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess === false) return;
    if (isSavingTemplate === false) return;

    success(t('message.success.redirecting'));
    setIsSavingTemplate(false);

    navigate(`/?userId=${userId}`);
  }, [isSuccess, isError]);

  /**
   * Handlers
   */
  const save = () => {
    updateTemplate({
      id,
      template: {
        save: stringifyState({
          id,
          operatorId,
          name,
          domain,
          type,
          variant,
          graphs,
          entries,
          config: {
            updateDiscardIds: discardFields,
            preconfiguration,
          },
        }),
      },
    });
  };

  return { save, isSuccess, isError };
};
