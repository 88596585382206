import { Accordion, Box, Group, Table, TextInput } from '@mantine/core';
import { RenameFormLayout, StyledAccordionControl } from './styles';
import { CategoryItemMenu } from '../category-item-menu';
import { useTemplateAccordionItem } from './use-template-accordion-item';
import { Cancel, Complete } from '@morph-mapper/ui';
import { match } from 'ts-pattern';
import { t } from 'i18next';
import { TemplateCategory } from '@morph-mapper/types';

interface TemplateAccordionItemProps {
  category: TemplateCategory;
  children: React.ReactNode;
}

export const TemplateAccordionItem = ({
  category,
  children,
}: TemplateAccordionItemProps) => {
  /* Hooks */
  const {
    categoryName,
    isEditing,
    setIsEditing,
    handleNameChange,
    handleRenameSave,
    handleRenameCancel,
    renameSaveDisabled,
  } = useTemplateAccordionItem(category);

  return (
    <Accordion.Item value={category.id}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {match(isEditing)
          .with(true, () => (
            <RenameFormLayout>
              <Group position="apart">
                <TextInput
                  type="text"
                  size="sm"
                  value={categoryName}
                  onChange={handleNameChange}
                  autoFocus
                />
                <Group>
                  <Complete
                    onClick={handleRenameSave}
                    disabled={renameSaveDisabled}
                  />
                  <Cancel onClick={handleRenameCancel} />
                </Group>
              </Group>
            </RenameFormLayout>
          ))
          .with(false, () => (
            <>
              <StyledAccordionControl>
                <span>{categoryName}</span>
              </StyledAccordionControl>

              {categoryName !== 'default' && (
                <Box sx={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                  <CategoryItemMenu
                    category={category}
                    setIsEditing={setIsEditing}
                  />
                </Box>
              )}
            </>
          ))
          .exhaustive()}
      </Box>

      <Accordion.Panel>
        <Table>
          <thead>
            <tr>
              <th>{t('table.headers.enabled')}</th>
              <th>{t('table.headers.template')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </Table>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
