import { z } from 'zod';
import { FiAnchor, FiCalendar } from 'react-icons/fi';
import {
  TbBoxMultiple,
  TbBracketsContainEnd,
  TbBracketsContainStart,
  TbColumns3,
  TbDecimal,
  TbEqual,
  TbLogicAnd,
  TbLogicOr,
  TbMathFunction,
  TbMinus,
  TbNumbers,
  TbPlus,
  TbSeeding,
  TbTable,
  TbTableAlias,
  TbX,
  TbMathGreater,
  TbMathEqualGreater,
  TbMathEqualLower,
  TbMathLower,
  TbExclamationMark,
  TbVariable,
} from 'react-icons/tb';
import { TfiSplitH } from 'react-icons/tfi';
import { CgMathDivide } from 'react-icons/cg';
import { LuEraser, LuReplaceAll, LuSplit, LuGitBranch } from 'react-icons/lu';
import { DiMongodb } from 'react-icons/di';
import { VscSymbolString } from 'react-icons/vsc';
import { AiOutlineMergeCells } from 'react-icons/ai';
import { MdOutlineStop, MdDataArray } from 'react-icons/md';
import { PiArrowUp, PiTextbox } from 'react-icons/pi';
import { BlockRule, GraphNode, typeLogicBlockRecord } from '../../types';
import { Input } from '@morph-mapper/node-inputs';
import {
  HandleType,
  OptionType,
  reservedWrapVariables,
} from '@morph-mapper/types';
import { ReservedNodeType } from '@morph-mapper/types';
import { replaceEscapedCharacter } from '@morph-mapper/utils';

/**
 * FIXME: This function is a temporary fix to refit the absolute path to a local reference.
 * This function has several limitations and should be implicit in the context of the application later.
 * This function SHOULD be removed.
 *
 * - limit: absolute paths are now not available.
 * - limit: not aware of how paths work, may break if the path is a variable referencing another path.
 */
const replaceInObjRecursive = (
  obj: any,
  search: string,
  replace: (v: string) => string
) => {
  if (typeof obj === 'string') {
    return replace(obj);
  }

  for (const key in obj) {
    if (typeof obj[key] === 'string' && obj[key].startsWith('$inputdocument')) {
      obj[key] = replace(obj[key]);
    } else if (typeof obj[key] === 'object') {
      replaceInObjRecursive(obj[key], search, replace);
    }
  }

  return obj;
};

const filterIncomingPathReference = (value: any, reference: string) => {
  const replacePathString = (v: string) => {
    return (
      '$data.' +
      v
        .split('.')
        .slice(reference.split('.').length - 1)
        .join('.')
    );
  };

  if (typeof value === 'object') {
    // Replace all strings which start with $inputdocument
    return replaceInObjRecursive(value, reference, replacePathString);
  }

  return replacePathString(value);
};

const LOGIC_BLOCKS_INTERNAL = typeLogicBlockRecord({
  seed: {
    title: 'Seed',
    description:
      'The seed is the starting point of your template. It can be a URL, a file or a variable.',
    category: 'internal',
    type: 'seed',
    node: GraphNode.Seed,
    icon: TbSeeding,
    mutation: () => undefined,
    options: {},
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
  result: {
    title: 'Output',
    description: 'The output is the final result of your template.',
    category: 'internal',
    type: 'result',
    node: GraphNode.Output,
    icon: MdOutlineStop,
    mutation: ({ tree }) => tree(),
    options: {},
    handles: {
      source: [],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
  context: {
    title: 'Context',
    description: 'Inject into the context of the variable.',
    category: 'internal',
    type: 'context',
    node: GraphNode.Generic,
    icon: MdOutlineStop,
    mutation: ({ tree }) => tree(),
    options: {},
    handles: {
      source: [],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
});

const LOGIC_BLOCKS_CORE = typeLogicBlockRecord({
  anchor: {
    title: 'Anchor',
    description:
      'Select text to act as a reference point of the value you want to extract.',
    category: 'core',
    type: 'anchor',
    node: GraphNode.Generic,
    icon: FiAnchor,
    mutation: ({ options }) => {
      const { iterate, skipWords, shift, selected, occurrence, whitespace } =
        options;

      if (!selected) return;

      const renderIterate = () => {
        if (!iterate) return '';
        return iterate ? '>' : '';
      };
      const renderCount = () => {
        if (!shift) return '';
        return shift != 0 ? shift : '';
      };
      const renderSkipWords = () => {
        if (!skipWords) return '';
        return skipWords.length ? '|' + skipWords.join(',') : '';
      };
      const renderOccurrence = () => {
        if (!occurrence) return '';
        if (skipWords.length === 0) {
          // Skipwords does not render a pipe when undefined, so we need to add it here
          return occurrence > 0 ? `||${occurrence}` : '';
        }

        return occurrence > 0 ? `|${occurrence}` : '';
      };

      const anchor = `#${renderIterate()}${renderCount()}${renderSkipWords()}${renderOccurrence()}#${selected}`;

      if (whitespace) {
        return [[anchor]];
      } else {
        return anchor;
      }
    },
    options: {
      selected: {
        type: z.string(),
        title: 'Selector',
        description:
          'Select text from file which will act as a reference to extract data',
        input: Input.TextSelector,
        default: undefined,
        conditions: {},
      },
      iterate: {
        type: z.boolean(),
        title: 'Iterate',
        description: 'Use this variable with an iterator.',
        input: Input.Checkbox,
        default: false,
        conditions: {},
      },
      whitespace: {
        type: z.boolean(),
        title: 'Whitespace',
        description: 'Strip trailing whitespace in the output.',
        input: Input.Checkbox,
        default: false,
        conditions: {},
      },
      skipWords: {
        type: z.array(z.string()),
        title: 'Ignored words',
        description: 'Skip defined words and/or characters',
        input: Input.MultiSelectCreatable,
        default: [],
        conditions: {},
      },
      shift: {
        type: z.number(),
        title: 'Shift',
        description: 'Amount of words and/or characters to skip',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
      occurrence: {
        type: z.number(),
        title: 'Occurrence',
        description: 'Which occurrence of the anchor to use',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
  literal: {
    title: 'Literal',
    description:
      'Add a literal value to your template. This can be a string, a number or a boolean.',
    category: 'core',
    type: 'literal',
    node: GraphNode.Generic,
    icon: VscSymbolString,
    mutation: ({ options }) => {
      const { literal } = options;

      if (!literal) return;

      return literal;
    },
    options: {
      literal: {
        type: z.any(),
        title: 'Literal',
        description: 'Add a constant value to the template',
        input: Input.TextInput,
        default: undefined,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
  docVariables: {
    title: 'Document Variables',
    description: 'Acess a document variable in your template.',
    category: 'core',
    type: 'docVariables',
    node: GraphNode.Generic,
    icon: TbVariable,
    mutation: ({ options }) => {
      const { variable } = options;

      if (reservedWrapVariables.has(variable)) {
        const [mappedObject] = reservedWrapVariables.get(variable)!;
        return mappedObject;
      }

      return variable;
    },
    options: {
      variable: {
        type: z.enum([
          '$emailMessage.text',
          '$emailMessage.from.0.address',
          '$emailMessage.subject',
          '$pdfString',
          '$emailData.fileName',
          '$xlsColumn',
        ]),
        title: 'Variable',
        description: 'Select one of the available document variables',
        input: Input.SingleSelect,
        selection: [
          { value: '$emailMessage.text', label: 'Email Body' },
          { value: '$emailMessage.from.0.address', label: 'Email From' },
          { value: '$emailMessage.subject', label: 'Email Subject' },
          { value: '$pdfString', label: 'PDF Body' },
          { value: '$emailData.fileName', label: 'File Name' },
          { value: '$xlsColumn', label: 'XLS Column' },
        ],
        default: '',
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
});

const LOGIC_BLOCKS_TRANSFORMER = typeLogicBlockRecord({
  concatenate: {
    title: 'Concatenate',
    description: 'Combine two or more values into a single string.',
    category: 'transformer',
    type: 'concatenate',
    node: GraphNode.Generic,
    icon: AiOutlineMergeCells,
    mutation: ({ tree }) => {
      return {
        '$utils.concat.selective': tree(),
      };
    },
    options: {},
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.MultipleChildren],
  },
  split: {
    title: 'Split',
    description:
      'Split a string, based on a separator, into an array of strings.',
    category: 'transformer',
    type: 'split',
    node: GraphNode.Generic,
    icon: TfiSplitH,
    mutation: ({ tree, options }) => {
      const { separator, select } = options;

      if (!separator) return;

      return {
        [ReservedNodeType.DeclareVariable]: {
          qa: tree(),
          qb: {
            '$qa.split': separator,
            $result: {
              $getVariable: `data.${select}`,
            },
          },
        },
        $getVariable: 'qb',
      };
    },
    options: {
      separator: {
        type: z.string(),
        title: 'Separator',
        description: 'The separator to split the string on.',
        input: Input.TextInput,
        default: undefined,
        conditions: {},
      },
      select: {
        type: z.number(),
        title: 'Select',
        description: 'Select the index of the split string to return.',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
  dateTime: {
    title: 'Date and Time',
    description:
      'Transform a value into a date, transform date formats or output the UNIX time.',
    category: 'transformer',
    type: 'dateTime',
    node: GraphNode.Generic,
    icon: FiCalendar,
    mutation: ({ tree, options }) => {
      const { inputFormat, outputFormat, outputSelection } = options;

      if (!inputFormat) return;
      if (outputSelection === 'custom' && !outputFormat) return;

      if (outputSelection === 'unix') {
        return {
          '$time.stamp': [tree(), inputFormat],
        };
      }

      return {
        '$time.format': [
          {
            '$time.stamp': [tree(), inputFormat],
          },
          outputFormat,
        ],
      };
    },
    options: {
      inputFormat: {
        type: z.string(),
        title: 'Input Format',
        description: 'Date and/or time formatting of incoming value',
        input: Input.TextInput,
        default: undefined,
        conditions: {},
      },
      outputSelection: {
        type: z.enum(['unix', 'custom']),
        title: 'Output Type',
        description: 'Select the date conversion type',
        input: Input.RadioSelector,
        selection: [
          { value: 'unix', label: 'Epoch' },
          { value: 'custom', label: 'Custom' },
        ],
        default: 'unix',
        conditions: {},
      },
      outputFormat: {
        type: z.string(),
        title: 'Output Format',
        description: 'Date and/or time formatting of incoming value',
        input: Input.TextInput,
        default: undefined,
        conditions: {
          hasValue: {
            outputSelection: 'custom',
          },
        },
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
  substring: {
    title: 'SubString',
    description: 'Extract a substring from a string.',
    category: 'transformer',
    type: 'substring',
    node: GraphNode.Generic,
    icon: PiTextbox,
    mutation: ({ tree, options }) => {
      const { from, useEnd, to } = options;

      const renderIndices = () => {
        if (useEnd) return [from, to];
        return from;
      };

      return {
        [ReservedNodeType.DeclareVariable]: {
          varToParse: tree(),
        },
        '$varToParse.substring': renderIndices(),
      };
    },
    options: {
      from: {
        type: z.number(),
        title: 'From',
        description: 'The starting index of the substring',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
      useEnd: {
        type: z.boolean(),
        title: 'Use End',
        description: 'Use an index to define the end of the substring',
        input: Input.Checkbox,
        default: true,
        conditions: {},
      },
      to: {
        type: z.number(),
        title: 'To',
        description: 'The ending index of the substring',
        input: Input.Number,
        default: 0,
        conditions: {
          hasValue: {
            useEnd: true,
          },
        },
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
  removeWords: {
    title: 'Remove Words',
    description: 'Remove words from a string.',
    category: 'transformer',
    type: 'removeWords',
    node: GraphNode.Generic,
    icon: LuEraser,
    mutation: ({ tree, options }) => {
      const { countStart, countEnd } = options;

      return {
        '$helpers.removeWords': [tree(), countStart, countEnd],
      };
    },
    options: {
      countStart: {
        type: z.number(),
        title: 'Starting count',
        description:
          'The amount of words to remove from the start of the string.',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
      countEnd: {
        type: z.number(),
        title: 'Ending count',
        description:
          'The amount of words to remove from the end of the string.',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
  removeSpecialCharacters: {
    title: 'Remove Special Characters',
    description:
      'Remove special characters and (trailing) whitespace from a string.',
    category: 'transformer',
    type: 'removeSpecialCharacters',
    node: GraphNode.Generic,
    icon: LuEraser,
    mutation: ({ tree, options }) => {
      const { trimWhitespace } = options;

      const renderTrim = (el: any) => {
        if (trimWhitespace) return [[el]];
        return el;
      };

      return {
        '$utils.regexp.replace': [
          renderTrim(tree()),
          ['[\n,\r, ,/,(,)]', 'g'],
          '',
        ],
      };
    },
    options: {
      trimWhitespace: {
        type: z.boolean(),
        title: 'Trim Whitespace',
        description: 'Remove trailing whitespace from the string',
        input: Input.Checkbox,
        default: true,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
  replaceSymbols: {
    title: 'Replace symbols',
    description: 'Replace symbols of 1 or more characters in a string',
    category: 'transformer',
    type: 'replaceSymbols',
    node: GraphNode.Generic,
    icon: LuReplaceAll,
    mutation: ({ tree, options }) => {
      const { symbols, target, global, caseInsensitive } = options;

      if (!target || !symbols) return;

      const renderFlags = () => {
        return `${global ? 'g' : ''}${caseInsensitive ? 'i' : ''}`;
      };

      const renderRegExp = (symbols: string | undefined) => {
        const flags = renderFlags();

        if (!flags && symbols === undefined) return [];
        if (!flags) return [symbols];
        if (symbols === undefined) return ['', flags];

        return [symbols, flags];
      };

      const singleCharSymbols = () => {
        return symbols.filter((symbol) => symbol.length === 1);
      };

      const multiCharSymbols = () => {
        return symbols.filter((symbol) => symbol.length > 1);
      };

      const renderSingleCharSymbols = () => {
        if (singleCharSymbols().length === 0) return tree();
        const symbolsArray = `[${singleCharSymbols().join(',')}]`;
        return {
          '$utils.regexp.replace': [tree(), renderRegExp(symbolsArray), target],
        };
      };

      const renderRecursive = (symbols: string[]): any => {
        if (symbols.length === 0) return renderSingleCharSymbols();
        return {
          '$utils.regexp.replace': [
            renderRecursive(symbols.slice(1)),
            renderRegExp(symbols[0]),
            target,
          ],
        };
      };

      return renderRecursive(multiCharSymbols());
    },
    options: {
      symbols: {
        type: z.array(z.string()),
        title: 'Symbols',
        description: 'The symbols to replace within the incoming string.',
        input: Input.MultiSelectCreatable,
        default: [],
        conditions: {},
      },
      target: {
        type: z.string(),
        title: 'Target',
        description: 'The target value to replace the symbols with.',
        input: Input.SingleSelectCreatable,
        selection: [
          { value: '', label: 'Empty' },
          { value: '\n', label: 'Newline' },
        ],
        default: undefined,
        conditions: {},
      },
      global: {
        type: z.boolean(),
        title: 'Global',
        description: 'Match all occurrences of the pattern',
        input: Input.Checkbox,
        default: false,
        conditions: {},
      },
      caseInsensitive: {
        type: z.boolean(),
        title: 'Case Insensitive',
        description: 'Ignore case when matching',
        input: Input.Checkbox,
        default: false,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
  parseToNumber: {
    title: 'Parse to Number',
    description: 'Parse a string to a number.',
    category: 'transformer',
    type: 'parseToNumber',
    node: GraphNode.Generic,
    icon: TbNumbers,
    mutation: ({ tree }) => {
      return {
        '$math.parseInt': tree(),
      };
    },
    options: {},
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
  parseToFloat: {
    title: 'Parse to Broken Number',
    description: 'Parse a string to a broken number (float).',
    category: 'transformer',
    type: 'parseToFloat',
    node: GraphNode.Generic,
    icon: TbNumbers,
    mutation: ({ tree }) => {
      return {
        '$math.parseFloat': tree(),
      };
    },
    options: {},
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
});

const LOGIC_BLOCKS_LOGIC = typeLogicBlockRecord({
  logicOr: {
    title: 'OR',
    description: 'Combine two or more values with an OR operator.',
    category: 'logic',
    type: 'logicOr',
    node: GraphNode.Generic,
    icon: TbLogicOr,
    mutation: ({ tree }) => {
      return {
        '$utils.or': tree(),
      };
    },
    options: {},
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.MultipleChildren],
  },
  logicAnd: {
    title: 'AND',
    description: 'Combine two or more values with an AND operator.',
    category: 'logic',
    type: 'logicAnd',
    node: GraphNode.Generic,
    icon: TbLogicAnd,
    mutation: ({ tree }) => {
      return {
        '$utils.and': tree(),
      };
    },
    options: {},
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.MultipleChildren],
  },
  logicEqual: {
    title: 'Equal',
    description: 'Check if two values are equal.',
    category: 'logic',
    type: 'logicEqual',
    node: GraphNode.Generic,
    icon: TbEqual,
    mutation: ({ tree }) => {
      return {
        '$utils.eq': tree(),
      };
    },
    options: {},
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.MultipleChildren],
  },
  logicNot: {
    title: 'Not',
    description: 'Invert a boolean value.',
    category: 'logic',
    type: 'logicNot',
    node: GraphNode.Generic,
    icon: TbExclamationMark,
    mutation: ({ tree }) => {
      return {
        '$utils.not': tree(),
      };
    },
    options: {},
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.SingleChild],
  },
  logicRegExpTest: {
    title: 'Regular Expression Test',
    description: 'Check if a value matches a regular expression.',
    category: 'logic',
    type: 'logicRegExpTest',
    node: GraphNode.Generic,
    icon: TbMathFunction,
    mutation: ({ tree, options }) => {
      const { pattern, global, caseInsensitive } = options;

      if (!pattern) return;

      const renderFlags = () => {
        return `${global ? 'g' : ''}${caseInsensitive ? 'i' : ''}`;
      };

      const renderRegExp = () => {
        const flags = renderFlags();
        if (!flags) return [pattern];

        return [pattern, flags];
      };

      const treeString = JSON.stringify(tree());

      const getWrapVariableForTree = () => {
        for (const [
          mappedObject,
          wrapVariable,
        ] of reservedWrapVariables.values()) {
          if (JSON.stringify(mappedObject) === treeString) {
            return wrapVariable;
          }
        }

        return null;
      };

      const wrapVariable = getWrapVariableForTree();

      // Return the wrapped construct if the tree() is a mapped variable in reservedWrapVariables
      if (wrapVariable) {
        return {
          [wrapVariable]: {
            '$utils.regexp.test': [tree(), renderRegExp()],
          },
        };
      }

      return {
        '$utils.regexp.test': [tree(), renderRegExp()],
      };
    },
    options: {
      pattern: {
        type: z.string(),
        title: 'Pattern',
        description: 'The regular expression pattern',
        input: Input.TextInput,
        default: undefined,
        conditions: {},
      },
      global: {
        type: z.boolean(),
        title: 'Global',
        description: 'Match all occurrences of the pattern',
        input: Input.Checkbox,
        default: false,
        conditions: {},
      },
      caseInsensitive: {
        type: z.boolean(),
        title: 'Case Insensitive',
        description: 'Ignore case when matching',
        input: Input.Checkbox,
        default: false,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
  logicIfElse: {
    title: 'Condition',
    description: 'Forward a value based on a condition.',
    category: 'logic',
    type: 'logicIfElse',
    node: GraphNode.Generic,
    icon: LuSplit,
    mutation: ({ options }) => {
      const { condition, then, otherwise } = options;

      if (!condition || !then || !otherwise) return;

      return {
        $if: condition,
        $then: then,
        $else: otherwise,
      };
    },
    options: {
      condition: {
        type: z.any(),
        title: 'Condition',
        description:
          'Expression or value to evaluate in order to determine which branch is passed on.',
        input: Input.TextInput,
        default: undefined,
        conditions: {
          isConnector: true,
        },
      },
      then: {
        type: z.any(),
        title: 'Then',
        description: 'Value to pass on if the condition is true.',
        input: Input.TextInput,
        default: undefined,
        conditions: {
          isConnector: true,
        },
      },
      otherwise: {
        type: z.any(),
        title: 'Else',
        description: 'Value to pass on if the condition is false.',
        input: Input.TextInput,
        default: undefined,
        conditions: {
          isConnector: true,
        },
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
  switchCase: {
    title: 'Switch Case',
    description: 'Evaluate multiple conditions using a switch-case structure.',
    category: 'logic',
    type: 'switchCase',
    node: GraphNode.Generic,
    icon: LuGitBranch,
    mutation: ({ options }) => {
      const { switchExpression, caseDefinition, defaultCase } = options;

      if (!switchExpression || !caseDefinition || !defaultCase) return;

      return {
        $switch: switchExpression,
        $case: caseDefinition,
        $default: defaultCase,
      };
    },
    options: {
      switchExpression: {
        type: z.any(),
        title: 'Switch',
        description: 'Expression or value to evaluate for different cases.',
        input: Input.TextInput,
        default: undefined,
        conditions: {
          isConnector: true,
        },
      },
      caseDefinition: {
        type: z.any(),
        title: 'Case',
        description: 'List of case conditions and their results.',
        input: Input.TextInput,
        default: undefined,
        conditions: {
          isConnector: true,
        },
      },
      defaultCase: {
        type: z.any(),
        title: 'Default',
        description: 'Action or value to use if no cases match.',
        input: Input.TextInput,
        default: undefined,
        conditions: {
          isConnector: true,
        },
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
});

const LOGIC_BLOCKS_MATH = typeLogicBlockRecord({
  mathPlus: {
    title: 'Plus',
    description: 'Add two or more values together.',
    category: 'math',
    type: 'mathPlus',
    node: GraphNode.Generic,
    icon: TbPlus,
    mutation: ({ tree }) => {
      return {
        '$math.sum': tree(),
      };
    },
    options: {},
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.MultipleChildren],
  },
  mathSubtract: {
    title: 'Subtract',
    description: 'Subtract one value from the other',
    category: 'math',
    type: 'mathSubtract',
    node: GraphNode.Generic,
    icon: TbMinus,
    mutation: ({ tree, options }) => {
      const { subtract } = options;

      return {
        '$math.minus': [tree(), subtract],
      };
    },
    options: {
      subtract: {
        type: z.number(),
        title: 'Subtract',
        description: 'Subtract this value from the incoming value',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
  mathMultiply: {
    title: 'Multiply',
    description: 'Multiply two or more values together.',
    category: 'math',
    type: 'mathMultiply',
    node: GraphNode.Generic,
    icon: TbX,
    mutation: ({ tree }) => {
      return {
        '$math.multiply': tree(),
      };
    },
    options: {},
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.MultipleChildren],
  },
  mathRound: {
    title: 'Round',
    description: 'Round a value to the nearest integer.',
    category: 'math',
    type: 'mathRound',
    node: GraphNode.Generic,
    icon: TbDecimal,
    mutation: ({ tree }) => {
      return {
        '$math.round': tree(),
      };
    },
    options: {},
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.SingleChild],
  },
  mathCeil: {
    title: 'Ceil',
    description: 'Round up a value to the nearest integer.',
    category: 'math',
    type: 'mathCeil',
    node: GraphNode.Generic,
    icon: TbBracketsContainEnd,
    mutation: ({ tree }) => {
      return {
        '$math.ceil': tree(),
      };
    },
    options: {},
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.SingleChild],
  },
  mathFloor: {
    title: 'Floor',
    description: 'Round down a value to the nearest integer.',
    category: 'math',
    type: 'mathFloor',
    node: GraphNode.Generic,
    icon: TbBracketsContainStart,
    mutation: ({ tree }) => {
      return {
        '$math.floor': tree(),
      };
    },
    options: {},
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.SingleChild],
  },
  mathDivide: {
    title: 'Divide',
    description: 'Divide one value by the other.',
    category: 'math',
    type: 'mathDivide',
    node: GraphNode.Generic,
    icon: CgMathDivide,
    mutation: ({ tree, options }) => {
      const { divisor } = options;

      return {
        '$math.divide': [tree(), divisor],
      };
    },
    options: {
      divisor: {
        type: z.number(),
        title: 'Divisor',
        description: 'Divide the incoming value by this value',
        input: Input.Number,
        default: 1,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
  mathMaximal: {
    title: 'Maximal',
    description: 'Get the maximal value of two or more values.',
    category: 'math',
    type: 'mathMaximal',
    node: GraphNode.Generic,
    icon: PiArrowUp,
    mutation: ({ tree }) => {
      return {
        '$math.max': tree(),
      };
    },
    options: {},
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.MultipleChildren],
  },
  mathLessThan: {
    title: 'Less Than',
    description: 'Check if a value is less than another value.',
    category: 'math',
    type: 'mathLessThan',
    node: GraphNode.Generic,
    icon: TbMathLower,
    mutation: ({ tree, options }) => {
      const { lessThan } = options;

      return {
        '$math.lt': [tree(), lessThan],
      };
    },
    options: {
      lessThan: {
        type: z.number(),
        title: 'Less Than',
        description: 'Check if the incoming value is less than this value',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
  mathLessThanOrEqual: {
    title: 'Less Than or Equal',
    description: 'Check if a value is less than or equal to another value.',
    category: 'math',
    type: 'mathLessThanOrEqual',
    node: GraphNode.Generic,
    icon: TbMathEqualLower,
    mutation: ({ tree, options }) => {
      const { lessThanOrEqual } = options;

      return {
        '$math.lte': [tree(), lessThanOrEqual],
      };
    },
    options: {
      lessThanOrEqual: {
        type: z.number(),
        title: 'Less Than or Equal',
        description:
          'Check if the incoming value is less than or equal to this value',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
  mathGreaterThan: {
    title: 'Greater Than',
    description: 'Check if a value is greater than another value.',
    category: 'math',
    type: 'mathGreaterThan',
    node: GraphNode.Generic,
    icon: TbMathGreater,
    mutation: ({ tree, options }) => {
      const { greaterThan } = options;

      return {
        '$math.gt': [tree(), greaterThan],
      };
    },
    options: {
      greaterThan: {
        type: z.number(),
        title: 'Greater Than',
        description: 'Check if the incoming value is greater than this value',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
  mathGreaterThanOrEqual: {
    title: 'Greater Than or Equal',
    description: 'Check if a value is greater than or equal to another value.',
    category: 'math',
    type: 'mathGreaterThanOrEqual',
    node: GraphNode.Generic,
    icon: TbMathEqualGreater,
    mutation: ({ tree, options }) => {
      const { greaterThanOrEqual } = options;

      return {
        '$math.gte': [tree(), greaterThanOrEqual],
      };
    },
    options: {
      greaterThanOrEqual: {
        type: z.number(),
        title: 'Greater Than or Equal',
        description:
          'Check if the incoming value is greater than or equal to this value',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
    },
    rules: [BlockRule.Configurable, BlockRule.SingleChild],
  },
});

const LOGIC_BLOCKS_REQUEST = typeLogicBlockRecord({
  genericRequest: {
    title: 'Generic Request',
    description:
      'Make a request to a database, use this component to implement custom logic.',
    category: 'request',
    type: 'genericRequest',
    node: GraphNode.Generic,
    icon: DiMongodb,
    mutation: ({ options }) => {
      const { database, collection, parameters, target } = options;

      if (!database || !collection || !target) return;

      return {
        $mongoRequest: [
          {
            [`$db.${collection}.findOne`]: {
              ...parameters,
            },
            $result: {
              $getVariable: target,
            },
          },
          database,
          [collection],
        ],
      };
    },
    options: {
      database: {
        type: z.string(),
        title: 'Database',
        description: 'The database which to fetch data from',
        input: Input.TextInput,
        default: undefined,
        conditions: {},
      },
      collection: {
        type: z.string(),
        title: 'Collection',
        description: 'The collection which to fetch data from',
        input: Input.TextInput,
        default: undefined,
        conditions: {},
      },
      target: {
        type: z.string(),
        title: 'Target',
        description: 'The target value to fetch from the resulting data',
        input: Input.TextInput,
        default: undefined,
        conditions: {},
      },
      parameters: {
        type: z.any(),
        title: 'Parameters',
        description: 'Define values which are passed to the database operator',
        // FIXME: input: Input.ParameterTable,
        input: Input.ParameterTable,
        default: {},
        conditions: {
          isFullWidth: true,
        },
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
  vesselRequest: {
    title: 'Vessel Request',
    description: 'Fetch vessel information from a database.',
    category: 'request',
    type: 'vesselRequest',
    node: GraphNode.Generic,
    icon: DiMongodb,
    mutation: ({ options }) => {
      const { database, collection, parameters, target } = options;

      return {
        $mongoRequest: [
          {
            [`$db.${collection}.findOne`]: {
              ...parameters,
            },
            $result: {
              $getVariable: target,
            },
          },
          database,
          [collection],
        ],
      };
    },
    options: {
      database: {
        type: z.literal('morphjs'),
        title: 'Database',
        description: 'The database which to fetch data from',
        input: Input.Literal,
        default: 'morphjs',
        conditions: {
          isStatic: true,
        },
      },
      collection: {
        type: z.literal('resource'),
        title: 'Collection',
        description: 'The collection which to fetch data from',
        input: Input.Literal,
        default: 'resource',
        conditions: {
          isStatic: true,
        },
      },
      target: {
        type: z.literal('CODE'),
        title: 'Target',
        description: 'The target value to fetch from the resulting data',
        input: Input.Literal,
        default: 'CODE',
        conditions: {
          isStatic: true,
        },
      },
      parameters: {
        type: z
          .object({
            operatorid: z.number(),
            alias: z.string(),
          })
          .required()
          .and(z.record(z.any())),
        title: 'Parameters',
        description: 'Define values which are passed to the database operator',
        // FIXME: parameters: Input.ParameterTable,
        input: Input.ParameterTable,
        default: {
          operatorid: {
            type: OptionType.Dependency,
            value: undefined,
          },
          alias: {
            type: OptionType.Dependency,
            value: undefined,
          },
        },
        conditions: {
          isFullWidth: true,
        },
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
});

const LOGIC_BLOCKS_TABLE = typeLogicBlockRecord({
  composeTableColumns: {
    title: 'Compose Table',
    description: 'Construct a table by automatically identifying the columns.',
    category: 'table',
    type: 'composeTableColumns',
    node: GraphNode.Generic,
    icon: TbTable,
    mutation: ({ options }) => {
      const { definitionInHeader, ...restOptions } = options;

      if (restOptions.from === undefined) {
        return;
      }

      if (definitionInHeader) {
        return {
          '#$composeTableColumns': restOptions,
        };
      }

      // If definitionInHeader is false, exclude 'fromLine'
      const { fromLine, ...filteredOptions } = restOptions;
      return {
        '#$composeTableColumns': filteredOptions,
      };
    },
    options: {
      from: {
        type: z.string(),
        title: 'From',
        description: 'Select the variable to compose',
        input: Input.TextSelector,
        default: undefined,
        conditions: {},
      },
      definitionInHeader: {
        type: z.boolean(),
        title: 'Table Definition in Header',
        description: 'Select if table definition starts from the header',
        input: Input.Checkbox,
        default: true,
        conditions: { isStatic: true },
      },
      lines: {
        type: z.number(),
        title: 'Lines',
        description:
          'The number of lines to skip from the marker before starting to compose the table',
        input: Input.Number,
        default: 1,
        conditions: {
          hasValue: {
            definitionInHeader: false,
          },
        },
      },
      fromLine: {
        type: z.number(),
        title: 'From Line',
        description: 'The starting point for column headers in the table',
        input: Input.Number,
        default: 0,
        conditions: { isHidden: true },
      },
      compose: {
        type: z.boolean(),
        title: 'Compose',
        description: 'Enable different compose algorithm to compose the table',
        input: Input.Checkbox,
        default: false,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
  defineTableCell: {
    title: 'Cell',
    description: 'Get information from a cell within a table.',
    category: 'table',
    type: 'defineTableCell',
    node: GraphNode.Generic,
    icon: TbTableAlias,
    mutation: ({ options }) => {
      const { shiftRows, shiftColumns, iterate, ...config } = options;

      const renderIterate = () => {
        if (iterate) return '>';
        return '';
      };

      return {
        [`#${renderIterate()}${shiftRows},${shiftColumns}$cell#`]: config,
      };
    },
    options: {
      shiftRows: {
        type: z.number(),
        title: 'Target Row',
        description: 'The row number of the cell',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
      shiftColumns: {
        type: z.number(),
        title: 'Target Column',
        description: 'The column number of the cell',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
      iterate: {
        type: z.boolean(),
        title: 'Iterate',
        description: 'Iterate over the cell',
        input: Input.Checkbox,
        default: false,
        conditions: {},
      },
      from: {
        type: z.string(),
        title: 'From',
        description: 'The from marker used in composing the table',
        input: Input.TextSelector,
        default: undefined,
        conditions: {},
      },
      fromLine: {
        type: z.number(),
        title: 'From Line',
        description: 'The line used in composing the table',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
      columns: {
        type: z.any(),
        title: 'Columns',
        description: 'Column definitions used to index the cells of the table',
        input: Input.TextInput,
        default: undefined,
        conditions: {
          isConnector: true,
        },
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
  iterateRowByColumn: {
    title: 'Column',
    description: 'Iterate over a column within a table.',
    category: 'table',
    type: 'iterateRowByColumn',
    node: GraphNode.Generic,
    icon: TbColumns3,
    mutation: ({ options }) => {
      const { column } = options;

      return `#>$column#${column}`;
    },
    options: {
      column: {
        type: z.number(),
        title: 'Column',
        description: 'Select target column to iterate over',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
});

const LOGIC_BLOCKS_ITERATOR = typeLogicBlockRecord({
  countOccurences: {
    title: 'Count Occurrences',
    description:
      'Iterate over a value which appears multiple times, used to relatively reference fields to extract.',
    category: 'iterator',
    type: 'countOccurences',
    node: GraphNode.Generic,
    icon: TbBoxMultiple,
    mutation: ({ options, ctx }) => {
      const { countoccurences, referBack } = options;
      const { isRoot } = ctx;

      const parseCountOccurences = (value: string) => {
        if (value && value.startsWith('[') && value.endsWith(']')) {
          return value
            .slice(1, -1)
            .split(',')
            .map((item) => item.trim().replace(/^"|"$/g, ''));
        }
        return value;
      };

      const parsedCountOccurences = countoccurences
        ? parseCountOccurences(countoccurences)
        : undefined;

      if (!parsedCountOccurences && !referBack) {
        return;
      }

      if (!referBack) {
        if (isRoot) {
          return { countoccurences: parsedCountOccurences };
        }

        return {
          [ReservedNodeType.Iterator]: {
            countoccurences: parsedCountOccurences,
          },
        };
      }

      if (isRoot) {
        return {
          countoccurences: parsedCountOccurences,
          referBack: replaceEscapedCharacter(referBack),
        };
      }

      return {
        [ReservedNodeType.Iterator]: {
          countoccurences: parsedCountOccurences,
          referBack: replaceEscapedCharacter(referBack),
        },
      };
    },
    options: {
      countoccurences: {
        type: z.string(),
        title: 'Count Occurences',
        description: 'Select text from file which will be used to iterate over',
        input: Input.TextSelector,
        default: undefined,
        conditions: {},
      },
      referBack: {
        type: z.string(),
        title: 'Refer Back',
        description:
          'Specify the starting point of the occurrence, either from the beginning of the line or a specific character/word before the keyword',
        input: Input.TextInput,
        default: undefined,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
  iterableTable: {
    title: 'Iterate Table',
    description: 'Iterate over a table.',
    category: 'iterator',
    type: 'iterableTable',
    node: GraphNode.Generic,
    icon: TbTable,
    mutation: ({ options, ctx }) => {
      const { from, to, findByColumns, columns, skip, fromLine, shiftRow } =
        options;
      const { isRoot } = ctx;

      if (isRoot) {
        return {
          table: {
            from,
            to,
            findByColumns,
            columns,
            skip,
            fromLine,
            shiftRow,
          },
        };
      }

      return {
        [ReservedNodeType.Iterator]: {
          table: {
            from,
            to,
            findByColumns,
            columns,
            skip,
            fromLine,
            shiftRow,
          },
        },
      };
    },
    options: {
      from: {
        type: z.string(),
        title: 'From Marker',
        description: 'The marker in the file to start composing the table from',
        input: Input.TextSelector,
        default: undefined,
        conditions: {},
      },
      to: {
        type: z.string(),
        title: 'To Marker',
        description: 'The marker in the file where to end composing the table',
        input: Input.TextSelector,
        default: undefined,
        conditions: {},
      },
      fromLine: {
        type: z.number(),
        title: 'From Line',
        description:
          'The number of lines to skip from the marker before starting to compose the table',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
      shiftRow: {
        type: z.number(),
        title: 'Shift Row',
        description:
          'The number of rows to skip from the start of the table before iterating over values',
        input: Input.Number,
        default: 0,
        conditions: {},
      },
      findByColumns: {
        type: z.boolean(),
        title: 'Find by Columns',
        description: 'Enable different compose algorithm to compose the table',
        input: Input.Checkbox,
        default: false,
        conditions: {},
      },
      columns: {
        type: z.any(),
        title: 'Columns',
        description: 'Column definitions used to index the cells of the table',
        input: Input.TextInput,
        default: undefined,
        conditions: {
          isConnector: true,
        },
      },
      skip: {
        type: z.any(),
        title: 'Skip',
        description: 'Define which rows to skip from the table',
        input: Input.TextInput,
        default: undefined,
        conditions: {
          isConnector: true,
        },
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
  getFromArray: {
    title: 'Get From List',
    description: 'Get element(s) from a list.',
    category: 'iterator',
    type: 'getFromArray',
    node: GraphNode.Generic,
    icon: MdDataArray,
    mutation: ({ options }) => {
      const { value, reference, skip, returnMode, targetElement } = options;

      const renderSkip = () => {
        if (!skip) return {};
        return { $skip: filterIncomingPathReference(skip, reference) };
      };

      const extractElement = () => {
        if (returnMode === 'all') return 'data';
        if (targetElement === undefined) return 'data';
        return 'data.' + targetElement;
      };

      if (!value && !reference) {
        return;
      }

      return {
        '$Array.forEach': filterIncomingPathReference(value, reference),
        $data: reference,
        ...renderSkip(),
        $result: {
          $getVariable: extractElement(),
        },
      };
    },
    options: {
      value: {
        type: z.any(),
        title: 'Value',
        description: 'The value to extract from the list',
        input: Input.TextSelector,
        default: undefined,
        conditions: {},
      },
      reference: {
        type: z.string(),
        title: 'Reference',
        description: 'The reference to the list to iterate over',
        input: Input.TextSelector,
        default: undefined,
        conditions: {},
      },
      skip: {
        type: z.boolean(),
        title: 'Skip',
        description: 'Determine if the current element should be skipped.',
        input: Input.TextInput,
        default: undefined,
        conditions: {
          isConnector: true,
        },
      },
      returnMode: {
        type: z.enum(['all', 'single']),
        title: 'Return Mode',
        description:
          'Select whether to return the entire list or a single element from the list.',
        input: Input.RadioSelector,
        selection: [
          { value: 'all', label: 'Entire List' },
          { value: 'single', label: 'Single Element' },
        ],
        default: 'all',
        conditions: {},
      },
      targetElement: {
        type: z.number(),
        title: 'Target Element',
        description: 'The target element to extract from the list',
        input: Input.Number,
        default: 0,
        conditions: {
          hasValue: {
            returnMode: 'single',
          },
        },
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
  sumFromArray: {
    title: 'Sum From List',
    description: 'Sum element(s) from a list.',
    category: 'iterator',
    type: 'sumFromArray',
    node: GraphNode.Generic,
    icon: MdDataArray,
    mutation: ({ options }) => {
      const { value, reference } = options;

      if (!value && !reference) return;

      return {
        '$Array.forEach': {
          '$math.parseFloat': filterIncomingPathReference(value, reference),
        },
        $data: reference,
        $result: {
          '$math.sum': '$data',
        },
      };
    },
    options: {
      value: {
        type: z.any(),
        title: 'Value',
        description: 'The value to extract from the list',
        input: Input.TextSelector,
        default: undefined,
        conditions: {},
      },
      reference: {
        type: z.string(),
        title: 'Reference',
        description: 'The reference to the list to iterate over',
        input: Input.TextSelector,
        default: undefined,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
});

const LOGIC_BLOCKS_CASE = typeLogicBlockRecord({
  caseDefinition: {
    title: 'Case Definition',
    description: 'Specify conditions within a switch-case structure.',
    category: 'case',
    type: 'caseDefinition',
    node: GraphNode.Generic,
    icon: TbBoxMultiple,
    mutation: ({ options }) => {
      const { variableKey, variableValue } = options;

      if (!variableKey || !variableValue) return;

      return [variableKey, variableValue];
    },
    options: {
      variableKey: {
        type: z.string(),
        title: 'Variable Key',
        description: 'Enter the name of the variable to define.',
        input: Input.TextInput,
        default: undefined,
        conditions: { isStatic: true },
      },
      variableValue: {
        type: z.string(),
        title: 'Variable Value',
        description: 'Enter the value for the variable.',
        input: Input.TextInput,
        default: undefined,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },
    rules: [
      BlockRule.Configurable,
      BlockRule.NoChildren,
      BlockRule.MultipleElement,
    ],
  },
});

export const LOGIC_BLOCKS = {
  ...LOGIC_BLOCKS_INTERNAL,
  ...LOGIC_BLOCKS_CORE,
  ...LOGIC_BLOCKS_TRANSFORMER,
  ...LOGIC_BLOCKS_LOGIC,
  ...LOGIC_BLOCKS_MATH,
  ...LOGIC_BLOCKS_REQUEST,
  ...LOGIC_BLOCKS_TABLE,
  ...LOGIC_BLOCKS_ITERATOR,
  ...LOGIC_BLOCKS_CASE,
};
