import "react-data-grid/lib/styles.css";
import DataGrid, { CellClickArgs } from "react-data-grid";
import { TableViewContent } from "./styles";
import { useTableFile } from "./use-table-file";
import { useTextSelect } from "../../hooks";
import { Footer } from "../../molecules";

export const TableView = () => {
  const { rows, columns, handleCellClick } = useTableFile();

  useTextSelect();

  type Row = {
    _id: number;
  };
  const rowKeyGetter = (row: Row) => row._id;

  return (
    <>
      <TableViewContent>
        <DataGrid
          className="rdg-light"
          columns={columns}
          rows={rows}
          style={{ flex: 1 }}
          rowKeyGetter={rowKeyGetter}
          onCellClick={handleCellClick}
        />
      </TableViewContent>
      <Footer />
    </>
  );
};
