import {
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  createCategory,
  deleteCategory,
  updateCategory,
  fetchCategoriesWithFilter,
  fetchTemplates,
  createTemplate,
  fetchTemplateById,
  deleteTemplate,
  updateTemplate,
  fetchTemplateDeploy,
  updateTemplateDeploy,
  deployTemplateSave,
} from './crud';
import {
  CreateTemplateResourceDto,
  TemplateCategory,
  TemplateDeploy,
  UpdateTemplateResourceDto,
} from '@morph-mapper/types';
import { reviveState } from '@morph-mapper/utils';
import { useNotify } from '@/hooks';
import { AxiosError } from 'axios';
import { t } from 'i18next';

// TODO: move
export type CategoryFilter = Partial<{
  operatorId: number;
  domain: string;
}>;

export type TemplateFilter = Partial<{
  operatorId: number;
}>;

// TODO: resolve name conflict. -> direct feedback in UI as well
// TODO: form
export const useCreateTemplate = () => {
  const queryClient = useQueryClient();
  const { success, error } = useNotify();

  return useMutation({
    mutationFn: (template: CreateTemplateResourceDto) =>
      createTemplate(template),
    onSuccess: () => {
      success(t('message.success.templateCreated'));
      queryClient.invalidateQueries({ queryKey: ['templates'] });
    },
    onError: (e) => {
      if (e instanceof AxiosError) {
        error(e.response?.data?.message);
      } else {
        error(t('message.error.templateCreationFailed'));
      }
    },
  });
};

export const useTemplatesWithFilter = ({ operatorId }: TemplateFilter) => {
  return useQuery({
    queryKey: ['templates', operatorId],
    queryFn: () => fetchTemplates({ operatorId }),
  });
};

export const useTemplate = (id: string) => {
  return useQuery({
    queryKey: ['template', id],
    queryFn: id ? () => fetchTemplateById(id) : skipToken,
    select: (data) => ({
      ...data,
      save: data.save ? reviveState(data.save) : undefined,
    }),
  });
};

export const useDeleteTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteTemplate(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates'] });
    },
  });
};

export const useUpdateTemplate = () => {
  const queryClient = useQueryClient();
  const { success, error } = useNotify();

  return useMutation({
    mutationFn: ({
      id,
      template,
    }: {
      id: string;
      template: UpdateTemplateResourceDto;
    }) => updateTemplate(id, template),
    onSuccess: () => {
      success(t('message.success.templateSaved'));
      queryClient.invalidateQueries({ queryKey: ['templates'] });
      queryClient.invalidateQueries({ queryKey: ['template'] });
    },
    onError: (e) => {
      if (e instanceof AxiosError) {
        error(e.response?.data?.message);
      } else {
        error(t('message.error.templateSavingFailed'));
      }
    },
  });
};

export const useTemplateDeploy = (id: string) => {
  return useQuery({
    queryKey: ['deploy', id],
    queryFn: id ? () => fetchTemplateDeploy(id) : skipToken,
  });
};

/**
 * Queries: Category
 **/

// Fetches all categories by operator id and current domain
export const useCategoriesWithFilter = ({
  operatorId,
  domain,
}: CategoryFilter) => {
  const isActive = operatorId !== undefined && domain !== undefined;

  return useQuery({
    queryKey: ['categories', operatorId, domain],
    queryFn: isActive
      ? () => fetchCategoriesWithFilter({ operatorId, domain })
      : skipToken,
    initialData: [],
  });
};

// Creates a new category
export const useCreateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (category: Omit<TemplateCategory, 'id'>) =>
      createCategory(category),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories'] });
    },
  });
};

// Deletes a category
export const useDeleteCategory = () => {
  const queryClient = useQueryClient();
  const { warn, error, success } = useNotify();

  return useMutation({
    mutationFn: (id: string) => deleteCategory(id),
    onSuccess: () => {
      success(t('message.success.categoryDeleted'));
      queryClient.invalidateQueries({ queryKey: ['categories'] });
    },
    onError: (e) => {
      if (e instanceof AxiosError) {
        warn(e.response?.data?.message);
      } else {
        error('An error occurred');
      }
    },
  });
};

// Updates a category
export const useUpdateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      category,
    }: {
      id: string;
      category: Partial<TemplateCategory>;
    }) => updateCategory(id, category),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories'] });
    },
  });
};

export const useUpdateTemplateDeploy = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, content }: { id: string; content: any }) =>
      updateTemplateDeploy(id, content),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['template-refs'] });
    },
  });
};

//TODO: verify function correctness of below functions
// Updates or creates a deployed template (if deployPath does not exist on template)
export const useUpdateDeployTemplateSave = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      save,
    }: {
      id: string;
      save: Omit<TemplateDeploy, 'id'>;
    }) => deployTemplateSave(id, save),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['template-refs'] });
    },
  });
};

// export const useRawDeployTemplateSave = () => {
//   const queryClient = useQueryClient();

//   return useMutation({
//     mutationFn: ({ id, content }: { id: string; content: string }) =>
//       rawDeployTemplateSave(id, content),
//     onSuccess: (data, variables) => {
//       queryClient.invalidateQueries({
//         queryKey: ['template-save', variables.id],
//       });
//       queryClient.refetchQueries({
//         queryKey: ['template-save', variables.id],
//       });
//     },
//   });
// };
