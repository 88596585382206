import { Accordion } from '@mantine/core';
import { useCategoriesWithFilter, useTemplatesWithFilter } from '@/services';
import { useSetupStore } from '../../store';
import { TemplateCategory, TemplateResource } from '@morph-mapper/types';
import { TemplateListItem } from '../template-list-item/template-list-item';
import { TemplateAccordionItem } from '../template-accordion-item';
import { getEntries } from '@morph-mapper/utils';
import { useEffect, useState } from 'react';

export const TemplateAccordion = () => {
  /* Context state */
  const [domain, operatorId] = useSetupStore((s) => [
    s.getDomain(),
    s.getOperatorId(),
  ]);
  /* Local state */
  const [categoriesRecord, setCategoriesRecord] = useState<
    Record<string, TemplateCategory>
  >({});
  /* Queries */
  const { data: categories, isSuccess } = useCategoriesWithFilter({
    operatorId,
    domain,
  });
  const { data: templates } = useTemplatesWithFilter({ operatorId });

  /**
   * Effects
   **/
  // Map the resulting categories array to a record, so we can easily index by
  // category id
  useEffect(() => {
    if (isSuccess === false) return;

    const map = categories.reduce((acc, category) => {
      acc[category.id] = category;

      return acc;
    }, {} as Record<string, TemplateCategory>);

    setCategoriesRecord(map);
  }, [isSuccess, categories]);

  // If the queries have not finished yet, do not render anything
  if (templates === undefined || categories === undefined) return null;

  /**
   * Handlers
   */
  const sortCategories = () => {
    return categories.sort((a, b) => {
      if (a.name === 'Other') return 1;
      if (b.name === 'Other') return -1;

      return a.name.localeCompare(b.name);
    });
  };

  const buildCategoriesObj = () => {
    return sortCategories().reduce((acc, category) => {
      acc[category.id] = [];
      return acc;
    }, {} as Record<string, TemplateResource[]>);
  };

  const groupByCategory = (templates: TemplateResource[]) => {
    return templates.reduce((acc, template) => {
      if (acc[template.categoryId] === undefined) {
        acc[template.categoryId] = [];
      }

      acc[template.categoryId].push(template);

      return acc;
    }, buildCategoriesObj());
  };

  const orderByPriority = (templates: TemplateResource[]) => {
    return templates.sort((a, b) => a.priority - b.priority);
  };

  const getDefaultCategory = () => {
    return categories.find((category) => category.name === 'default')?.id;
  };

  return (
    <Accordion
      chevronPosition="left"
      styles={{ content: { padding: 0 } }}
      defaultValue={getDefaultCategory()}
    >
      {getEntries(groupByCategory(orderByPriority(templates)))
        // The categoriesRecord is used before it has updated after a change in the categories,
        // so we filter to prevent to prevent undefined categories during the first render.
        .filter(([categoryId, _]) => categoriesRecord[categoryId] !== undefined)
        .map(([categoryId, templates]) => (
          <TemplateAccordionItem
            key={categoryId}
            category={categoriesRecord[categoryId]}
          >
            {templates.map((template) => (
              <TemplateListItem key={template.id} {...template} />
            ))}
          </TemplateAccordionItem>
        ))}
    </Accordion>
  );
};
