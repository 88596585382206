import { SideBarContent, SideBarWrapper } from './styles';
import { HeaderConfigDef } from './header';
import { ConfigAccordionLayout, SectionLayout } from '@/common';
import { useSchemaConfig, useSchemaEditor } from '@/hooks';
import { ConfigurationOption } from './configuration-option';
import { DocumentationModal } from '../documentation-modal';
import { Accordion } from '@mantine/core';

export const SchemaEditor = () => {
  const {
    getIdsForConfiguration,
    getKeyById,
    getConfigurationByKey,
    translateConfiguration,
  } = useSchemaConfig();
  const { isValidConfiguration } = useSchemaEditor();

  const translatedConfigurations = translateConfiguration(
    getIdsForConfiguration().reduce((acc, id) => {
      const key = getKeyById(id);
      const config = getConfigurationByKey(key);
      return {
        ...acc,
        [key]: config,
      };
    }, {})
  );

  // Filter standard and advanced configurations
  const standardConfigurations = getIdsForConfiguration().filter(
    (id) =>
      isValidConfiguration(id) &&
      !translatedConfigurations[getKeyById(id)].hidden &&
      !translatedConfigurations[getKeyById(id)].advanced
  );

  const advancedConfigurations = getIdsForConfiguration().filter(
    (id) =>
      isValidConfiguration(id) &&
      !translatedConfigurations[getKeyById(id)].hidden &&
      translatedConfigurations[getKeyById(id)].advanced
  );

  return (
    <SideBarWrapper>
      <HeaderConfigDef />
      <SideBarContent>
        {standardConfigurations.map((id) => {
          const key = getKeyById(id);
          const { title, description, hidden } = translatedConfigurations[key];

          if (hidden) return null;

          return title ? (
            <SectionLayout
              title={title}
              description={description || ''}
              key={key}
            >
              <ConfigurationOption id={id} configTitle={title} />
            </SectionLayout>
          ) : (
            <ConfigurationOption id={id} key={key} configTitle={title} />
          );
        })}

        {advancedConfigurations.length > 0 && (
          <ConfigAccordionLayout title="Advanced Configurations">
            {advancedConfigurations.map((id) => {
              const key = getKeyById(id);
              const { title, description } = translatedConfigurations[key];

              return title ? (
                <SectionLayout
                  title={title}
                  description={description || ''}
                  key={key}
                >
                  <ConfigurationOption id={id} />
                </SectionLayout>
              ) : (
                <ConfigurationOption id={id} key={key} />
              );
            })}
          </ConfigAccordionLayout>
        )}
      </SideBarContent>
      <DocumentationModal />
    </SideBarWrapper>
  );
};
