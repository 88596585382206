import styled from '@emotion/styled';

export const ModalContent = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 300,
}));

export const FormContent = styled.form(() => ({
  display: 'flex',
  overflowY: 'auto',
  flex: 1,
  flexDirection: 'column',
  gap: '1rem',
  marginTop: '0.5rem',
  marginBottom: '0.5rem',
}));
