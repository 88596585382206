import { type SchemaVariant } from '@morph-mapper/types';
import { useSchemaBuilder } from '../../../hooks/use-schema';
import { TreeNode, PreConfiguration } from '@/types';
import { useSchema, type SchemaType } from '@morph-mapper/schemas';
import {
  getEntries,
  getKeys,
  getValues,
  isNotUndefined,
} from '@morph-mapper/utils';
import { useNotify } from '../../../hooks/use-notify';
import { useStore } from '@/store';
import { t } from 'i18next';

export const useTemplateBuilder = (operatorId: number, domain: string) => {
  /* Global State */
  const [definedCustomSchemes] = useStore(({ config: c }) => [
    c.definedCustomSchemes,
  ]);

  const { getSchema } = useSchema(domain, definedCustomSchemes);
  const { buildAppState } = useSchemaBuilder();
  const { error } = useNotify();

  const findDiscardableIds = (
    type: SchemaType,
    variant: SchemaVariant,
    entries: Record<string, TreeNode>
  ) => {
    const schema = getSchema(type, variant);

    return schema.updateTemplateDiscardFields
      .map((fieldName) => {
        return getEntries(entries)
          .map(([id, entry]) => {
            if (entry.key === fieldName) {
              return id;
            }
          })
          .filter(isNotUndefined);
      })
      .flat();
  };

  const validateConfiguration = (
    preconfig: PreConfiguration,
    type: SchemaType,
    variant: SchemaVariant
  ) => {
    const { config } = getSchema(type, variant);
    const availableKeys = getKeys(config || {});

    getValues(preconfig).forEach(({ key }) => {
      if (!availableKeys.includes(key)) {
        throw new Error(
          `Key ${key} is not available in the schema configuration.`
        );
      }
    });
  };

  const buildTemplate = (
    name: string,
    type: SchemaType,
    variant: SchemaVariant
  ) => {
    const schema = getSchema(type, variant);
    if (schema === undefined) {
      error(t('message.error.schemaNotFound'));
      return;
    }

    const { entries, preconfiguration } = buildAppState(schema, {
      name,
      operatorId,
    });

    validateConfiguration(preconfiguration, type, variant);
    const updateDiscardIds = findDiscardableIds(type, variant, entries);

    return {
      operatorId,
      name,
      domain,
      type,
      variant,
      graphs: {},
      entries,
      config: {
        preconfiguration,
        updateDiscardIds,
      },
    };
  };

  return { buildTemplate };
};
