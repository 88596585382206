import { useNotify } from '@/hooks';
import { useEffect, useState } from 'react';
import { useSetupStore } from '../../store';
import { useCategoriesWithFilter, useUpdateCategory } from '@/services';
import { t } from 'i18next';
import { TemplateCategory } from '@morph-mapper/types';

export const useTemplateAccordionItem = (category: TemplateCategory) => {
  /* Global State */
  const [domain, operatorId] = useSetupStore((s) => [
    s.getDomain(),
    s.getOperatorId(),
  ]);
  /* Local State */
  const [categoryName, setCategoryName] = useState(category.name);
  const [isEditing, setIsEditing] = useState(false);
  /* Hooks */
  const { success, warn } = useNotify();
  /* Queries */
  const { data: categories } = useCategoriesWithFilter({ operatorId, domain });
  const { mutate: updateCategory, isSuccess: updateSuccess } =
    useUpdateCategory();

  /**
   * Effects
   */
  useEffect(() => {
    if (updateSuccess) {
      success(t('message.success.categoryRenamed'));
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (category.name !== categoryName && hasNameConflict(categoryName)) {
      warn(t('message.warning.categoryNameConflict'));
    }
  }, [categoryName]);

  /**
   * Handlers
   **/
  const handleRenameCancel = () => {
    setCategoryName(category.name);
    setIsEditing(false);
  };

  const handleRenameSave = () => {
    updateCategory({
      id: category.id,
      category: {
        name: categoryName,
      },
    });

    setIsEditing(false);
  };

  const hasNameConflict = (newName: string) => {
    if (categories === undefined) return;

    return categories.some((cat) => cat.name === newName);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategoryName(e.target.value);
  };

  const renameSaveDisabled =
    categoryName.trim() === '' || hasNameConflict(categoryName);

  return {
    categoryName,
    isEditing,
    setIsEditing,
    handleNameChange,
    handleRenameSave,
    handleRenameCancel,
    renameSaveDisabled,
  };
};
