import { ReservedNodeType } from '@morph-mapper/types';
import { z } from 'zod';

export const fileExtensionSchema = z.enum([
  'pdf',
  'xls',
  'xml',
  'txt',
  'eml',
  'message-txt',
  'message-html-txt',
]);
export const mergingTypeSchema = z.enum(['createIfNotExist', 'extend']);
export const workloadSchema = z.enum(['digest', 'capture']);
export const templateTypeSchema = z.enum([
  'order',
  'feature',
  'resource',
  'truck',
]);
export const docStatusSchema = z.enum(['New', 'Update']);
export const docTypeSchema = z.enum(['Import', 'Export']);
export const transportDirectionSchema = z.literal(
  '{"$if":{"$utils.eq":["$type","Import"]},"$then":"I","$else":"E"}'
);
export const orderSessionSchema = z.literal(
  '{"$if":{"$utils.eq":["$type","Import"]},"$then":"orderimport","$else":"orderexport"}'
);
export const loadingDischargeSchema = z.literal(
  '{"$if":{"$utils.eq":["$type","Import"]},"$then":"D","$else":"L"}'
);

export const isReservedNodeType = (nodeType: string): boolean => {
  return Object.values(ReservedNodeType).includes(nodeType as ReservedNodeType);
};
