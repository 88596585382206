import { useSetupStore } from '../../store';
import { useEffect } from 'react';
import { useNotify } from '@/hooks';
import { TemplateResource } from '@morph-mapper/types';
import { t } from 'i18next';
import { useCreateTemplate, useTemplate, useUpdateTemplate } from '@/services';
import { useForm, UseFormReturnType } from '@mantine/form';
import { FormValues, TransformFormValues, TransformValues } from './types';
import { stringifyState } from '@morph-mapper/utils';
import { match } from 'ts-pattern';
import {
  EdiFormValues,
  SourceFormValues,
  useCategoriesOptions,
  useEdiOptions,
  useSourceOptions,
} from '../../hooks';

export const useConfigureTemplate = () => {
  /* Context State */
  const [isOpen, modalMode, setOpen, clearTemplateId, templateId] =
    useSetupStore((s) => [
      s.isConfigModalOpen,
      s.configModalMode,
      s.setIsConfigModalOpen,
      s.clearSelectedTemplateId,
      s.getSelectedTemplateId(),
    ]);
  /* Hooks */
  const { error } = useNotify();
  const form = useForm<FormValues, TransformValues>({
    validate: {
      name: (value) =>
        value.trim() === '' ? t('message.error.required') : null,
      categoryId: (value) =>
        value === undefined ? t('message.error.required') : null,
      ediVariables: {
        user: (value) => (value === '' ? t('message.error.required') : null),
        password: (value) =>
          value === '' ? t('message.error.required') : null,
        endpoint: (value) =>
          value === '' ? t('message.error.required') : null,
      },
    },
    transformValues: (values) => {
      const { ediType, ediVariables } = values;

      return {
        ...values,
        ediVariables: transformEdiVariables(ediType, ediVariables),
      };
    },
  });
  const { getCategoryOptions } = useCategoriesOptions();
  // Due to typing limitations we cast the form to a subset of the form values.
  const { getSourceOptions, handleSourceChange } = useSourceOptions(
    form as unknown as UseFormReturnType<SourceFormValues & EdiFormValues>
  );
  const {
    getEdiTypes,
    getEdiInputs,
    handleEdiOptionChange,
    parseEdiVariables,
    transformEdiVariables,
  } = useEdiOptions(form as unknown as UseFormReturnType<EdiFormValues>);
  /* Queries */
  const { data: template, isFetched: isTemplateFetched } =
    useTemplate(templateId);
  const { mutate: createTemplate } = useCreateTemplate();
  const { mutate: updateTemplate } = useUpdateTemplate();

  /**
   * Handlers
   */
  const loadInitialValues = (template: TemplateResource) => {
    const {
      name,
      description,
      priority,
      categoryId,
      inputsource,
      ediType,
      ediVariables,
    } = template;

    form.setValues({
      name,
      description,
      priority,
      categoryId,
      inputsource,
      ediType,
      ediVariables: parseEdiVariables(ediVariables),
    });
  };

  const handleCloseModal = () => {
    setOpen(false);
    clearTemplateId();
  };

  const handleUpdateTemplate = (values: TransformFormValues) => {
    if (template === undefined) return;

    updateTemplate({
      id: templateId,
      template: {
        ...values,
      },
    });
  };

  const handleDuplicateTemplate = (values: TransformFormValues) => {
    if (template === undefined) return;

    createTemplate({
      ...template,
      ...values,
      save: stringifyState(template.save),
    });
  };

  const submit = (values: TransformFormValues) => {
    match(modalMode)
      .with('edit', () => {
        handleUpdateTemplate(values);
      })
      .with('duplicate', () => {
        handleDuplicateTemplate(values);
      })
      .exhaustive();

    handleCloseModal();
  };

  /**
   * Effects
   */
  // Wait for the template to be loaded before setting the initial values
  useEffect(() => {
    if (isTemplateFetched === false) return;
    if (template === undefined) {
      error(t('message.error.templateNotFound'));
      handleCloseModal();
      return;
    }

    loadInitialValues(template);
  }, [isTemplateFetched]);

  return {
    form,
    getSourceOptions,
    getCategoryOptions,
    getEdiTypes,
    getEdiInputs,
    handleEdiOptionChange,
    handleSourceChange,
    isOpen,
    modalMode,
    submit,
    handleCloseModal,
  };
};
