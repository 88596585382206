import { Button, Title } from '@mantine/core';
import { useSetupStore } from '../../store';
import { useNotify } from '@/hooks';
import { Container, ContentWrapper, StyledScrollArea } from './styles';
import {
  CategoryPopover,
  TemplateAccordion,
  TemplateSelectHeader,
} from '../../molecules';
import { CreateTemplateModal } from '../create-template-modal';
import { ConfigTemplateModal } from '../config-template-modal';
import { t } from 'i18next';

export interface TemplateSelectProps {
  children: (progress: () => void, disabled?: boolean) => JSX.Element;
}

export const TemplateSelect = ({ children }: TemplateSelectProps) => {
  const [
    setIsCreateModalOpen,
    templateId,
    step,
    setCurrentStep,
    selectedTemplateId,
  ] = useSetupStore((s) => [
    s.setIsCreateModalOpen,
    s.templateId,
    s.currentStep,
    s.setCurrentStep,
    s.selectedTemplateId,
  ]);
  const { warn } = useNotify();

  const handleCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const progressSetup = () => {
    if (templateId === undefined) {
      warn(t('message.warning.selectTemplate'));

      return;
    }

    setCurrentStep(step + 1);
  };

  return (
    <>
      <Container>
        <ContentWrapper>
          <Title order={3} fw={500}>
            {t('title.templateSelect')}
          </Title>

          <TemplateSelectHeader>
            <CategoryPopover />
          </TemplateSelectHeader>

          <StyledScrollArea h={600} scrollbarSize={4}>
            <TemplateAccordion />
          </StyledScrollArea>

          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Button onClick={handleCreateModal}>
              {t('button.createTemplate')}
            </Button>
          </div>
        </ContentWrapper>
      </Container>

      <CreateTemplateModal />
      {selectedTemplateId && <ConfigTemplateModal />}

      {children(progressSetup)}
    </>
  );
};
