import { useStore } from '@/store';
import { useNotify } from '@/hooks';
import { useEffect } from 'react';
import { DOCS_USER_BASE_URL } from '@/config';
import { useUpdateTemplateDeploy } from '@/services';
import { useTimeout } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';

interface JSONValidationResult {
  isValid: boolean;
  errorMessage?: string;
  lineNumber?: number;
  column?: number;
}

export const useEditorHeader = (rawText: string) => {
  const [
    setNavTemplateOpen,
    setNavDomainOpen,
    isSavingTemplate,
    setIsSavingTemplate,
    userId,
    id,
  ] = useStore(({ config: c }) => [
    c.setIsNavTemplateModalOpen,
    c.setisNavDomainModalOpen,
    c.isSavingTemplate,
    c.setIsSavingTemplate,
    c.userId,
    c.getId(),
  ]);

  const {
    mutate: rawDeployTemplate,
    isSuccess: deploySuccess,
    isError: deployError,
  } = useUpdateTemplateDeploy();

  const { success, error, warn } = useNotify();
  const navigate = useNavigate();

  /**
   * Effects
   */

  useEffect(() => {
    if (isSavingTemplate) {
      handleSaveFile();
    }
  }, [isSavingTemplate]);

  const { start } = useTimeout(() => {
    navigate(`/?userId=${userId}`);
  }, 4000);

  useEffect(() => {
    if (deploySuccess) {
      success('Template has been deployed.');
      if (isSavingTemplate) {
        start();
        success('Redirecting...');
        setIsSavingTemplate(false);
      }
    }
    if (deployError) {
      error('Failed to deploy template.');
    }
  }, [deploySuccess, deployError]);

  /**
   * Handlers
   **/

  const validateJSON = (text: string): JSONValidationResult => {
    try {
      JSON.parse(text);
      return { isValid: true };
    } catch (e: any) {
      const errorMessage = e.message;

      const match = errorMessage.match(/line (\d+)/);

      if (match) {
        const lineNumber = parseInt(match[1], 10) - 1;

        return {
          isValid: false,
          errorMessage: `Error on line ${lineNumber}. Please review that line for issues.`,
          lineNumber,
        };
      }

      return {
        isValid: false,
        errorMessage:
          'Invalid JSON format. Unable to determine the exact error line.',
      };
    }
  };

  const handleSaveFile = async () => {
    const { isValid, errorMessage } = validateJSON(rawText);

    if (!isValid) {
      warn(errorMessage || 'Invalid JSON format.');
      setIsSavingTemplate(false);
      return;
    }

    rawDeployTemplate({
      id,
      content: rawText,
    });
  };
  const handleDocumentation = () => {
    window.open(DOCS_USER_BASE_URL, '_blank');
  };

  const handleNavigateToTemplateSelection = () => {
    setNavTemplateOpen(true);
  };

  const handleNavigateToDomainSelection = () => {
    setNavDomainOpen(true);
  };

  return {
    handleSaveFile,
    handleDocumentation,
    handleNavigateToTemplateSelection,
    handleNavigateToDomainSelection,
  };
};
