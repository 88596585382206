import { UseFormReturnType } from '@mantine/form';
import { ediOptionInputs, EdiType } from '@morph-mapper/types';
import { getEntries, getValues } from '@morph-mapper/utils';
import { match } from 'ts-pattern';

export type EdiFormValues = {
  ediType: EdiType | undefined;
  ediVariables: Record<string, string | number>;
};

const getEdiTypes = () => {
  return getValues(EdiType).map((type) => ({
    key: type,
    value: type,
    label: type.charAt(0).toUpperCase() + type.slice(1),
  }));
};

// Construct the EDI variables object based on the selected EDI type,
// these values are managed by the form hook.
const getEdiVariables = (ediType: EdiType): [id: string, value: string][] => {
  return ediOptionInputs[ediType]
    .filter((field) => !field.internal)
    .map(({ id }) => [id, '']);
};

const parseEdiVariables = (
  ediVariables:
    | Record<string, { value: string | number; schemaVariable: string }>
    | undefined
): Record<string, string | number> => {
  if (ediVariables === undefined) return {};

  return getEntries(ediVariables).reduce((acc, [key, { value }]) => {
    return {
      ...acc,
      [key]: value,
    };
  }, {} as Record<string, string | number>);
};

// Derive internal values and add schema variables
// which can be understood by the templating engine
const transformEdiVariables = (
  ediType: EdiType | undefined,
  ediVariables: Record<string, string | number>
) => {
  if (ediType === undefined) return {};

  return ediOptionInputs[ediType].reduce((acc, { id, schemaVariable }) => {
    return match(id)
      .with('oauth', () => {
        const { user, password } = ediVariables;
        const oauthValue = Buffer.from(`${user}:${password}`).toString(
          'base64'
        );

        return {
          ...acc,
          oauth: {
            value: oauthValue,
            schemaVariable,
          },
        };
      })
      .otherwise(() => ({
        ...acc,
        [id]: {
          value: ediVariables[id],
          schemaVariable,
        },
      }));
  }, {} as Record<string, { value: string | number; schemaVariable: string }>);
};

export const useEdiOptions = (form: UseFormReturnType<EdiFormValues>) => {
  // Dynamically generate the EDI inputs based on the selected EDI type
  const getEdiInputs = (): [id: string, type: string, label: string][] => {
    const ediType = form.values.ediType;

    if (ediType === undefined) return [];

    return ediOptionInputs[ediType]
      .filter((field) => !field.internal)
      .map(({ id, type, label }) => [id, type, label]);
  };

  const handleEdiOptionChange = (type: EdiType) => {
    form.setFieldValue<'ediType'>('ediType', type);

    getEdiVariables(type).forEach(([id, value]) => {
      form.setFieldValue(`ediVariables.${id}`, value);
    });
  };

  return {
    getEdiTypes,
    getEdiVariables,
    getEdiInputs,
    handleEdiOptionChange,
    parseEdiVariables,
    transformEdiVariables,
  };
};
