import { Menu, Portal } from '@mantine/core';
import { Options } from '@morph-mapper/ui';
import { FiTrash2 } from 'react-icons/fi';
import { AiFillEdit } from 'react-icons/ai';
import { TemplateCategory } from '@morph-mapper/types';
import { useDeleteCategory } from '@/services';

interface CategoryItemMenuProps {
  category: TemplateCategory;

  setIsEditing: (isEditing: boolean) => void;
}

export const CategoryItemMenu = ({
  category,
  setIsEditing,
}: CategoryItemMenuProps) => {
  /* Queries */
  const { mutate: deleteCategory } = useDeleteCategory();

  /**
   * Handlers
   */
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    deleteCategory(category.id);
  };

  const handleRename = () => {
    setIsEditing(true);
  };

  return (
    <Menu
      trigger="hover"
      openDelay={200}
      closeDelay={400}
      position="right-start"
    >
      <Menu.Target>
        <Options variant="subtle" />
      </Menu.Target>
      <Portal>
        <Menu.Dropdown>
          <Menu.Item icon={<AiFillEdit />} onClick={handleRename}>
            Rename
          </Menu.Item>
          <Menu.Item icon={<FiTrash2 />} color="red" onClick={handleDelete}>
            Delete Category
          </Menu.Item>
        </Menu.Dropdown>
      </Portal>
    </Menu>
  );
};
