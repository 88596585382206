import { BsTable } from "react-icons/bs";
import { Action, ActionProps } from "../action";
import { forwardRef } from "react";

export const CellClipboard = forwardRef<HTMLButtonElement, ActionProps>(
  (props: ActionProps, ref) => {
    return (
      <Action {...props} ref={ref} Icon={BsTable} variant="default" />
    );
  }
);
