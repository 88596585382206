import {
  Box,
  Button,
  Group,
  Modal,
  NumberInput,
  PasswordInput,
  Radio,
  Select,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useCreateTemplate } from './use-create-template';
import { t } from 'i18next';
import { FormContent, ModalContent } from './styles';
import { SchemaSource } from '@morph-mapper/types';
import { match } from 'ts-pattern';

export const CreateTemplateModal = () => {
  const {
    form,
    getSchemaTypeOptions,
    getSchemaVariantOptions,
    getSourceOptions,
    getCategoryOptions,
    getEdiTypes,
    getEdiInputs,
    handleEdiOptionChange,
    handleSourceChange,
    isOpen,
    handleCloseModal,
    submit,
  } = useCreateTemplate();

  return (
    <Modal
      opened={isOpen}
      onClose={handleCloseModal}
      title={t('title.newTemplate')}
      centered
      size="40%"
    >
      <ModalContent>
        <FormContent onSubmit={form.onSubmit(submit)}>
          <TextInput
            {...form.getInputProps('name')}
            label={t('common.name')}
            withAsterisk
            data-autofocus
          />

          <Radio.Group
            {...form.getInputProps('type')}
            label={t('common.inputSource')}
            withAsterisk
          >
            <Group mt="md">
              {getSchemaTypeOptions().map((option) => (
                <Radio {...option} />
              ))}
            </Group>
          </Radio.Group>

          <Radio.Group
            {...form.getInputProps('variant')}
            label={t('common.templateVariant')}
            withAsterisk
          >
            <Group mt="md">
              {getSchemaVariantOptions().map((option) => (
                <Radio {...option} />
              ))}
            </Group>
          </Radio.Group>

          <Radio.Group
            {...form.getInputProps('inputsource')}
            label={t('common.inputSource')}
            withAsterisk
            onChange={handleSourceChange}
          >
            <Group mt="md">
              {getSourceOptions().map((option) => (
                <Radio {...option} />
              ))}
            </Group>
          </Radio.Group>

          {form.values.inputsource === SchemaSource.Http && (
            <Radio.Group
              {...form.getInputProps('ediType')}
              label={t('common.ediOptions')}
              withAsterisk
              onChange={handleEdiOptionChange}
            >
              <Group mt="md">
                {getEdiTypes().map((option) => (
                  <Radio {...option} />
                ))}
              </Group>
            </Radio.Group>
          )}

          {getEdiInputs().map(([id, type, label]) =>
            match(type)
              .with('text', () => (
                <TextInput
                  key={id}
                  label={label}
                  withAsterisk
                  {...form.getInputProps(`ediVariables.${id}`)}
                />
              ))
              .with('number', () => (
                <NumberInput
                  key={id}
                  label={label}
                  withAsterisk
                  {...form.getInputProps(`ediVariables.${id}`)}
                />
              ))
              .with('password', () => (
                <PasswordInput
                  key={id}
                  label={label}
                  withAsterisk
                  {...form.getInputProps(`ediVariables.${id}`)}
                />
              ))
              .otherwise(() => (
                <TextInput
                  key={id}
                  label={label}
                  withAsterisk
                  {...form.getInputProps(`ediVariables.${id}`)}
                />
              ))
          )}

          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Select
              {...form.getInputProps('categoryId')}
              label={t('common.category')}
              data={getCategoryOptions()}
              withAsterisk
            />

            <NumberInput
              {...form.getInputProps('priority')}
              label={t('common.priority')}
              withAsterisk
            />
          </Box>

          <Textarea
            {...form.getInputProps('description')}
            label={t('common.description')}
          />

          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="submit">{t('button.create')}</Button>
          </Box>
        </FormContent>
      </ModalContent>
    </Modal>
  );
};
