import { RouterProvider } from 'react-router-dom';
import { Overview } from '../overview';
import { Suspense } from 'react';
import { sentryCreateBrowserRouter } from '../../../../instrument';
import { GlobalErrorBoundary } from '../../molecules';
import {
  ContextEditor,
  EntryEditor,
  GraphEditor,
  IteratorSelector,
  NodeConfigurator,
  NodeCreator,
  Setup,
  SchemaEditor,
  StructureSelector,
  GraphErrorBoundary,
} from '@/components';
import { RawEditor } from '../editor';

const graphRouter = [
  {
    path: 'graph',
    children: [
      {
        path: ':graphId?',
        errorElement: <GraphErrorBoundary />,
        children: [
          {
            index: true,
            element: <GraphEditor />,
          },
          {
            path: 'nodes',
            children: [
              {
                path: 'new',
                element: <NodeCreator />,
              },
              {
                path: ':nodeId',
                children: [
                  {
                    path: 'config',
                    element: <NodeConfigurator />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    errorElement: <GlobalErrorBoundary />,
    children: [
      {
        path: '/:userIdParam?',
        element: <Setup />,
      },
      {
        path: '/editor',
        element: (
          <Suspense>
            <RawEditor />
          </Suspense>
        ),
      },
      {
        element: (
          <Suspense>
            <Overview />
          </Suspense>
        ),
        children: [
          {
            path: 'configure',
            element: <SchemaEditor />,
          },
          {
            path: 'entries',
            children: [
              {
                index: true,
                element: <EntryEditor />,
              },
              {
                path: ':entryId',
                children: [
                  ...graphRouter,
                  {
                    path: 'context',
                    children: [
                      {
                        index: true,
                        element: <ContextEditor />,
                      },
                      {
                        path: 'iterator',
                        children: [
                          ...graphRouter,
                          {
                            path: 'new',
                            element: <IteratorSelector />,
                          },
                        ],
                      },
                      {
                        path: 'structure',
                        element: <StructureSelector />,
                      },
                      {
                        path: ':variableId',
                        children: [...graphRouter],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
